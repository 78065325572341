@media #{$breakpoint-lg-only} {
  .l-home {
    .teaser-homepage {
      .teaser-image {
        a {
          &:focus {
            span {
              background: $colourSweetCorn;
              color: $colourBlack; } } } }

      .teaser-body {
        .teaser-title {
          a {
            &:focus {
              background: $colourSweetCorn;
              text-decoration: none; } } } } }

    .quote-homepage {
      .quote {
        &.with-image {
          padding-left: $baseUnit*5;
          padding-right: $baseUnit*5; } }

      .quote-image {
        width: $baseUnit*25; }

      .btn {
        margin-top: $baseUnit*2;
        margin-bottom: $baseUnit*6; } } } }
