$inputHeight: $baseUnit*4.5;

form {
  margin-bottom: $baseUnit*2.4;

  &.form {
    &-inline {
      width: 100%;
      position: relative;

      #query {
        height: $baseUnit*4;
        max-width: 100%;
        min-width: 100%;
        box-sizing: border-box; }

      .search-submit {
        top: 0;
        position: absolute;
        right: 0;
        background: transparent;
        border: none;
        bottom: 0;
        padding: 0;
        width: $baseUnit*4;

        .icon {
          @include square(map-get($svgWidths, 'md'));
          fill: $colourGreyDark;
          margin-right: 0; } }

      .btn {
        margin-bottom: 0;
        border: none; } } }

  .form {
    &-group {
      margin-top: $baseUnit*3;

      &:first-of-type {
        margin-top: 0; }

      &.sub-question {
        margin-top: 0;
        border-left: ($baseUnit / 2) solid $colourGreyLighter;
        padding-top: $baseUnit;
        padding-left: $baseUnit*2; } }

    &-hint {
      color: $colourGreyDarkest;
      font-family: $brandFamily; }

    &-controls {
      margin-top: ($baseUnit * 4); }

    &-search {
      input[type='text'],
      input[type='password'],
      input[type='datetime'],
      input[type='datetime-local'],
      input[type='date'],
      input[type='month'],
      input[type='time'],
      input[type='week'],
      input[type='number'],
      input[type='email'],
      input[type='url'],
      input[type='search'],
      input[type='tel'],
      input[type='color'] {
        padding: $baseUnit*1.5;
        padding-right: $baseUnit*3.5; }

      &.form-search-large {
        select,
        textarea,
        input[type='text'],
        input[type='password'],
        input[type='datetime'],
        input[type='datetime-local'],
        input[type='date'],
        input[type='month'],
        input[type='time'],
        input[type='week'],
        input[type='number'],
        input[type='email'],
        input[type='url'],
        input[type='search'],
        input[type='tel'],
        input[type='color'] {
          @include border-radius($baseUnit*0.2);
          font-size: map-get($fontSizes, 'lg');
          padding: $baseUnit*2;
          padding-right: $baseUnit*3.8; } }

      &.form-search-larger {
        select,
        textarea,
        input[type='text'],
        input[type='password'],
        input[type='datetime'],
        input[type='datetime-local'],
        input[type='date'],
        input[type='month'],
        input[type='time'],
        input[type='week'],
        input[type='number'],
        input[type='email'],
        input[type='url'],
        input[type='search'],
        input[type='tel'],
        input[type='color'] {
          @include border-radius($baseUnit*0.2);
          font-size: map-get($fontSizes, 'lg');
          padding: $baseUnit*3 $baseUnit*4 $baseUnit*3 $baseUnit*3; } } } }

  .radio-option,
  .checkbox-option {
    position: relative;
    padding-left: 0;
    margin-bottom: 0;

    input[type="radio"],
    input[type="checkbox"] {
      height: ($baseUnit * 3.4);
      width: ($baseUnit * 3.4);
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      margin-left: 0;

      & + .radio-option-label {
        font-family: $brandFamily;
        padding: $baseUnit*0.7 0 0 $baseUnit*4.5;
        min-height: 34px;
        position: relative;
        margin-bottom: $baseUnit;
        cursor: pointer;

        &::before {
          @include border-radius(50%);
          content: "";
          border: $baseUnit*0.2 solid $colourGreyDarker;
          height: ($baseUnit * 3.4);
          width: ($baseUnit * 3.4);
          position: absolute;
          left: 0;
          top: 0;
          padding: $baseUnit*0.5;
          pointer-events: all; }

        &::after {
          @include border-radius(50%);
          content: "";
          position: absolute;
          top: 7px;
          left: 7px;
          width: ($baseUnit * 2);
          height: ($baseUnit * 2);
          opacity: 0;
          background: $colourGreyDarker;
          pointer-events: all; } }

      & + .checkbox-option-label {
        font-family: $brandFamily;
        padding: $baseUnit*0.7 0 0 $baseUnit*4.5;
        min-height: 34px;
        position: relative;
        margin-bottom: $baseUnit;
        cursor: pointer;

        &::before {
          @include border-radius($baseUnit*0.4);
          content: "";
          border: $baseUnit*0.2 solid $colourGreyDarker;
          height: ($baseUnit * 3.4);
          width: ($baseUnit * 3.4);
          position: absolute;
          left: 0;
          top: 0;
          pointer-events: none; }

        &::after {
          content: "";
          position: absolute;
          top: 9px;
          left: 7px;
          width: ($baseUnit * 2);
          height: 11px;
          opacity: 0;
          border: solid;
          border-width: 0 0 $baseUnit*0.5 $baseUnit*0.5;
          border-top-color: transparent;
          transform: rotate(-45deg);
          pointer-events: none; } }

      &:focus {
        & + label {
          outline: none;

          &:before {
            box-shadow: 0 0 0 $baseUnit*0.3 $colourSweetCorn; } } } } }

  .validation-errors {
    border: $baseUnit*0.1 solid $colourRed;
    padding: $baseUnit*2;

    h4 {
      margin-bottom: $baseUnit; }

    ul {
      margin-bottom: 0;

      li {
        color: $colourRed;

        a,
        a:hover {
          color: $colourRed;
          text-decoration: none; } } } }

  .question-error {
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"],
    input[type="search"],
    input[type="tel"],
    input[type="color"] {
      border-color: $colourRed; }

    &-msg {
      color: $colourRed; } }

  .highlighted-info {
    width: calc(100% - 20px);
    padding: ($baseUnit * 2);
    border-left: $baseUnit*0.5 solid $colourRed;
    margin-left: ($baseUnit * 2);

    p {
      &:first-child {
        margin-bottom: 0;
        font-family: $boldFontFamily; }

      &:last-of-type {
        margin-bottom: 0; } } }

  .accordion-small {
    .accordion-toggle {
      font-size: map-get($fontSizes, 'normal'); }

    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"],
    input[type="search"],
    input[type="tel"],
    input[type="color"] {
      @include border-radius($baseUnit*0.4); } }

  .controls,
  .sq-form-question,
  .decision-question {
    ul li {
      border: none;

      &:hover {
        border: none; }

      label {
        background: $colourTransparent; } } }

  select,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"] {
    @include border-radius($baseUnit*0.4);
    padding: $baseUnit;
    height: $inputHeight;
    border: $baseUnit*0.2 solid $colourGreyDarker; }

  select.form-control,
  select {
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat;
    background-color: $colourWhite;
    background-position-x: calc(100% - #{$baseUnit});
    appearance: none;
    padding: $baseUnit*0.7 $baseUnit*3.5 $baseUnit $baseUnit;

    &[multiple="multiple"] {
      background: none; } }

  textarea {
    @include border-radius($baseUnit*0.4);
    height: auto;
    padding: $baseUnit;
    border: $baseUnit*0.2 solid $colourGreyDarker;
    width: 100%;
    min-height: ($baseUnit * 12);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 $baseUnit*0.3 $colourSweetCorn; } }

  input[type="radio"]:checked + label.radio-option-label::after,
  input[type="checkbox"]:checked + label.checkbox-option-label::after {
    opacity: 1; }

  .plaintextedit[type=text],
  .tag-lookup-input,
  #address-lookup-input {
    height: auto !important; }

  li, td {
    position: relative;

    input[type="checkbox"]:checked + label::after,
    input[type="radio"]:checked + label::after {
      opacity: 1; } }

  .success-message,
  .warning-message,
  .error-message {
    margin-bottom: $baseUnit*0.8; }

  .success-message {
    color: $colourGreen; }

  .error-message {
    color: $colourRed; } }

.form-label,
label {
  font-family: $boldFontFamily;
  margin-bottom: $baseUnit*0.8;

  + .question-error-msg {
    margin-top: -$baseUnit*0.8; } }

input:invalid {
  color: $colourRed; }

.help-inline,
.help-block {
  color: $colourGrey; }

.help-inline {
  padding-left: $baseUnit*0.5; }

.help-block {
  margin-bottom: $baseUnit*1.2; }

abbr.sq-form-required-field[title] {
  color: $colourRed;
  border-bottom: none; }

.sq-form {
  &-required-field-note {
    margin-bottom: $baseUnit*2; }

  &-question {
    margin-bottom: $baseUnit*2;

    &-note {
      font-style: normal;
      margin-bottom: $baseUnit;
      display: block; }

    &-error {
      input {
        border-color: $colourRed; }

      .sq-form-question-title {
        color: $colourRed; } }

    &-answer {
      input,
      textarea,
      select {
        margin-bottom: 0; }

      textarea {
        width: 100%; }

      ul {
        list-style-type: none;
        margin: 0 0 $baseUnit 0;

        li {
          input {
            float: left;
            margin-right: $baseUnit; } } } }

    &-option-list {
      .sq-form-option-answer {
        li {
          display: block; }

        label {
          display: inline-block;
          margin-bottom: 0; } } } }

  &-error {
    color: $colourRed;
    margin: $baseUnit 0 0 0; } }

@import 'form-lg-up';
