.nav-filter {
  margin-bottom: 0;
  margin-left: -$baseUnit;
  margin-right: -$baseUnit;

  .navbar-inner {
    @include border-radius(0);
    background: $colourGreyLight;
    border: none;
    margin: 0;
    padding: 0;
    min-height: 40px;

    .btn-navbar {
      font-size: 1.125rem;
      line-height: 1.25rem;
      position: relative;
      min-height: 40px;
      color: $colourGrey;
      text-shadow: none;
      padding-left: $baseUnit*2;
      padding-right: $baseUnit*2;
      padding-top: $baseUnit;
      margin: 0;

      &.btn {
        width: 100%;
        background: $colourTransparent;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.btn-success {
          .icon {
            position: absolute;
            right: 0;
            top: 0;
            margin: $baseUnit*1.5 $baseUnit;
            fill: $colourWhite; } }

        .icon {
          fill: $colourRed; } }

      &.collapsed {
        .icon {
          fill: $colourBlack; } } }

    .nav-collapse {
      background: $colourAlabaster;
      margin-bottom: 0;

      .nav {
        &.flex-column {
          margin: 0;

          .nav-item {
            background: $colourWhite;
            padding: $baseUnit*0.9 $baseUnit*1.5;
            margin: $baseUnit*0.8 0 $baseUnit*0.1;

            &:first-child {
              margin-top: 0; }

            .nav-link {
              color: $colourBlack;
              font-size: $baseUnit*1.4;
              text-shadow: none;
              font-weight: normal;
              text-decoration: none;
              float: none;
              margin: 0 0 0 $baseUnit*1.3;
              padding: 0;

              .icon {
                @include square($iconSize18); } } } } } } }

  &.active {
    background: $colourJade;

    .btn-navbar {
      color: $colourWhite;
      font-size: 1.125rem;
      line-height: 1.25rem;
      position: relative;
      min-height: 40px;
      text-align: left;
      text-shadow: none;
      padding-left: $baseUnit*2;
      padding-right: $baseUnit*2;
      padding-top: $baseUnit;
      margin: 0;

      &.btn {
        width: 100%;
        background: $colourTransparent;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon {
          fill: $colourWhite; } } } } }

@import 'nav-filter-md-up';
