.r-document {
  .document-content,
  .back-to-top {
    font-size: map-get($font20, 'size');
    line-height: map-get($font20, 'line-height'); }

  .content {
    padding-bottom: 0; }

  .document {
    &-section {
      &__number {
        color: $colourGrey;
        font-family: $boldFontFamily; } }

    &-control-table {
      margin-top: $baseUnit * 4;
      padding-bottom: calc(#{$documentPaddingBottom} + #{$baseUnit * 2.4} + #{1.875rem});

      .table {
        margin-bottom: 0; } } }

  h2, h3, h4, h5 {
    @extend .f-fg-med; }

  h1 {
    font-size: map-get($font50, 'size');
    line-height: map-get($font50, 'line-height'); }

  h2 {
    font-size: map-get($font28, 'size');
    line-height: map-get($font28, 'line-height'); }

  h3 {
    font-size: map-get($font24, 'size');
    line-height: map-get($font24, 'line-height'); }

  h4 {
    font-size: map-get($font20, 'size');
    line-height: 1.5625rem; }

  h3, h4, p {
    .document-section__number {
      margin-right: $baseUnit * 1.5; } }

  .table {
    font-size: map-get($font18, 'size');
    line-height: map-get($font18, 'line-height');

    tr {
      &:last-child {
        border-bottom: ($baseUnit * 0.2) solid $colourGreyLight; }

      &:first-child {
        border-top: ($baseUnit * 0.2) solid $colourGreyLight; } }

    th {
      font-size: map-get($font18, 'size');
      line-height: map-get($font18, 'line-height'); }

    td,
    th {
      padding-top: $baseUnit * 1.5;
      padding-bottom: $baseUnit * 1.5;
      border-top: ($baseUnit * 0.2) solid $colourGreyLight; } } }

@import "document-md-up";
@import "document-lg-up";
