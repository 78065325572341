.footer-fixed {
  @extend .fixed-bottom;
  padding: #{$baseUnit * 2};

  &.footer-primary {
    background: $colourRed; }

  .footer-fixed {
    &-title,
    &-contact {
      color: $colourWhite;
      margin: 0; }

    &-contact {
      line-height: 1.25rem;
      font-size: 1.375rem;
      margin-top: $baseUnit;

      a {
        color: $colourWhite;
        text-decoration: underline;

        &:hover {
          color: $colourWhite; } }
      .icon {
        @include square(map-get($svgWidths, 'md') - 2);
        fill: $colourWhite;
        margin-right: $baseUnit;
        float: left; } }

    &-title {
      font: normal normal 0.9375rem / 1.1875rem  $boldFontFamily; } } }

@import 'footer-fixed-sm-up';
@import 'footer-fixed-md-up';
@import 'footer-fixed-lg-up';
