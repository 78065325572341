$border-lg-width: 2px;
$border-xl-width: 5px;

.border {
  &-grey-light {
    border-color: $colourGreyLight !important; }

  &-lg {
    border: $border-lg-width solid $border-color !important;

    &-top {
      border-top: $border-lg-width solid $border-color !important; }

    &-right {
      border-right: $border-lg-width solid $border-color !important; }

    &-bottom {
      border-bottom: $border-lg-width solid $border-color !important; }

    &-left {
      border-left: $border-lg-width solid $border-color !important; } }

  &-xl {
    border: $border-xl-width solid $border-color !important;

    &-top {
      border-top: $border-xl-width solid $border-color !important; }

    &-right {
      border-right: $border-xl-width solid $border-color !important; }

    &-bottom {
      border-bottom: $border-xl-width solid $border-color !important; }

    &-left {
      border-left: $border-xl-width solid $border-color !important; } } }
