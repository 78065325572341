.r-feature {
  .page-title,
  h2 {
    @extend .page-section-heading;
    font-family: $boldFontFamily;
    text-align: left;
    margin-top: $baseUnit*4;
    letter-spacing: -0.04rem;

    &:after {
      margin: $baseUnit 0 0 0; } }

  .page-title {
    margin-top: 0;
    font-size: map-get($font40, 'size');
    line-height: map-get($font40, 'line-height');
    font-family: $demiFontFamily; }

  h2 {
    font-size: map-get($font36, "size");
    line-height: map-get($font36, "line-height"); }

  .footer-local-title,
  .footer-local-subtitle,
  .footer-section-title,
  .teaser-title,
  .download-title {
    letter-spacing: 0;

    &:after {
      content: none; } }

  .teaser {
    &-title {
      font-size: map-get($headingFontSizes, "normal");
      line-height: map-get($headingLineHeights, "normal");
      margin-bottom: $baseUnit;
      margin-top: $baseUnit*2; }

    &--video {
      .teaser {
        &-title {
          margin-top: 0; } } } }

  .download-title {
    margin-top: 0; }

  .footer-local {
    &-title {
      margin-top: 0;
      margin-bottom: $baseUnit*2;
      font-size: map-get($headingFontSizes, 'normal');
      line-height: map-get($headingLineHeights, 'normal'); }

    &-subtitle {
      margin-top: 0;
      margin-bottom: $baseUnit;
      line-height: map-get($lineHeights, "normal");
      font-size: map-get($fontSizes, "normal"); } }

  .quote {
    margin: $baseUnit*4 0; }

  .highlight {
    margin-top: $baseUnit*2;
    margin-bottom: 0; } }


@import 'feature-md-up';
@import 'feature-lg-up';
