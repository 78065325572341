@media #{$breakpoint-xs-up} {
  .checkbox-btn-group,
  .radio-btn-group {
    flex-direction: row;

    > .checkbox-option,
    > .radio-option {
      margin-bottom: 0;
      margin-right: $itemGap;

      &:last-child {
        margin: 0; } }

    &-bordered {
      flex-direction: column;

      > .checkbox-option,
      > .radio-option {
        margin-bottom: 0;
        margin-right: 0; } } } }
