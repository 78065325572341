@media #{$breakpoint-lg-up} {
  .image {
    &.with-video {
      height: 420px;
      padding-top: 0;

      img {
        height: 100%; }

      .video {
        right: #{$imageVideoOffsetMD};
        bottom: #{$imageVideoOffsetMD};
        width: #{$imageVideoWidthLG};
        height: #{$imageVideoHeightLG};

        &--active {
          left: calc(100% - #{$imageVideoWidthLG + $imageVideoOffsetMD}); } }

      &.video-only {
        height: 322px;
        margin: (-61px) 0;

        .video-wrapper {
          position: relative;
          top: 0;
          bottom: 0;
          left: 0;
          width: 570px;
          height: 322px; }

        &.video-mode {
          .video-wrapper {
            position: absolute;
            width: 100%;
            height: 100%; } } } }

    &.with-icon-and-text {
      height: 420px;
      padding-top: 0;

      img {
        height: 100%; }

      .image-main {
        position: absolute;
        max-width: 100%;
        right: 0;
        bottom: 0;
        width: $baseUnit * 50;
        background: rgba($colourBlack, 0.8); } } } }
