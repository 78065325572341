ul.list {
  list-style: disc; }

.list {
  &.list-links {
    margin-left: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0 0 ($baseUnit / 2) 0;

      a {
        font-family: $brandFamily;
        text-decoration: none;

        &:hover {
          text-decoration: underline; } } }

    &-large {
      li {
        margin-bottom: $baseUnit * 1.5;

        a {
          font-size: 1.375rem;
          text-decoration: underline;
          font-family: $boldFontFamily; } }

      .icon {
        @include square(map-get($svgWidths, 'xs'));

        margin: 0 0 ($baseUnit * 0.1) ($baseUnit * 0.5); } }

    .list-links {
      margin: #{$baseUnit / 2} 0 0 #{$baseUnit * 2}; }

    &[class*='list-columns'] {
      columns: 1; } }

  &-profiles {
    @include clearfix;

    margin: 0 0 ($baseUnit * 2) 0;
    padding: 0;
    list-style: none;

    .icon {
      color: $colourGrey; }

    .profile {
      margin-bottom: $baseUnit; } }

  &-icons {
    margin: 0;
    padding-bottom: $baseUnit * 1.5;

    li {
      margin-right: $baseUnit;
      list-style: none;
      padding-left: map-get($svgWidths, 'sm') + 10px;
      text-indent: -#{map-get($svgWidths, 'sm') + 10px};

      .icon {
        @include square(map-get($svgWidths, 'sm'));

        /* Keep 10px as when we migrate to rems, calcs will fail */
        padding: 0;
        margin-right: 10px;
        display: inline-block; }

      a {
        text-decoration: none;

        .icon-tile.icon {
          margin-right: 0; } } }

    &.icons-lg,
    &.icons-md {
      li {
        padding-left: map-get($svgWidths, 'md') + 10px;
        text-indent: -#{map-get($svgWidths, 'md') + 10px};

        .icon {
          @include square(map-get($svgWidths, 'md'));

          @extend .icon-md; } } }

    &.no-padding {
      li {
        padding-left: 0;
        text-indent: 0; } } }

  .list-links {
    margin-left: $baseUnit * 2;
    margin-top: $baseUnit / 2; }

  &.list-horizontal {
    @include clearfix;

    list-style: none;
    margin: 0 0 ($baseUnit * 2) 0;
    padding: 0;

    li,
    dd,
    dt {
      display: block;
      float: left;
      margin-right: $baseUnit * 2; }

    dt {
      clear: left; } }

  &-horizontal.list-icons {
    li {
      text-indent: 0;
      padding: 0;
      margin-right: $baseUnit;

      &:last-child {
        margin-right: 0; }

      .icon {
        margin-right: 0; } } }

  &.list-publications {
    list-style: none;
    margin: 0 0 ($baseUnit * 2) 0;
    padding: 0;

    li {
      margin-bottom: $baseUnit / 2; } }

  &-steps {
    counter-reset: li;
    margin-left: 0;
    padding-left: 0;

    > li {
      position: relative;
      margin: 0 0 $baseUnit #{$baseUnit * 4};
      padding: #{$baseUnit / 2} 0 0 #{$baseUnit * 2};
      list-style: none;
      min-height: $baseUnit * 4; }

    > li:before {
      @include square($baseUnit * 4);
      @include border-radius($baseUnit * 2);

      content: counter(li);
      counter-increment: li;
      position: absolute;
      top: 0;
      left: #{-($baseUnit * 4)};
      line-height: $baseUnit * 4;
      margin-right: $baseUnit;
      color: $colourWhite;
      background: $colourRed;
      font-family: $headingsFontFamily;
      text-align: center;
      font-size: map-get($fontSizes, 'lg'); }

    li ol,
    li ul {
      margin-top: $baseUnit; }

    ol li:last-child {
      margin-bottom: 0; }

    &-small {
      counter-reset: li;
      margin-left: 0;
      padding-left: 0;

      > li {
        position: relative;
        padding: #{$baseUnit * 2} 0 #{$baseUnit * 2} #{$baseUnit * 3};
        list-style: none;
        min-height: $baseUnit * 4;
        border-top: ($baseUnit * 0.1) solid $colourGrey;

        p {
          margin-bottom: 0; }

        &:first-child {
          border-top: none;
          padding-top: 0;

          &:before {
            top: 0; } } }

      > li:before {
        @include square($baseUnit * 2);
        @include border-radius($baseUnit * 2);

        content: counter(li);
        counter-increment: li;
        position: absolute;
        top: $baseUnit * 2;
        left: 0;
        line-height: $baseUnit * 2;
        margin-right: $baseUnit;
        color: $colourBlack;
        background: $colourGreySoft;
        font-family: $headingsFontFamily;
        text-align: center;
        font-size: map-get($fontSizes, 'normal') - 5; }

      li ol,
      li ul {
        margin-top: $baseUnit; }

      ol li:last-child {
        margin-bottom: 0; } } }

  &-facts {
    @extend .d-flex;

    @extend .flex-nowrap;

    margin-left: 0;

    .fact {
      list-style: none;
      margin-bottom: 0;
      border-bottom: none;
      border-top: none;
      padding-right: 10px;
      padding-left: 10px;

      &:first-child {
        margin-left: 0; } } }

  &-tags {
    margin: 0 0 ($baseUnit * 1.5) 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }

  &.list-no-disc {
    list-style-type: none;
    margin-left: 0; }

  &-xs {
    li {
      margin-bottom: 0; } }

  &-sm {
    li {
      margin-bottom: $baseUnit * 0.5; } }

  &-contact {
    @extend .list-icons;

    @extend .text-sm;

    li {
      margin: 0 0 ($baseUnit * 0.5);

      .icon {
        margin-right: $baseUnit * 0.5; }

      p {
        margin-bottom: $baseUnit; } } }

  &-horizontal {
    &.pipe-separated {
      li {
        margin-right: 0;
        margin-bottom: $baseUnit * 0.5;
        display: block;
        float: none;

        &:last-child {
          margin-bottom: 0px; } } } } }

ol.list {
  counter-reset: item;

  ol {
    margin-top: $baseUnit * 0.5;
    margin-left: 0;
    counter-reset: item; }

  li {
    display: grid;
    grid-template-areas: 'number content';
    position: relative;
    width: 100%;
    width: fit-content;
    justify-content: flex-start;

    &:before {
      content: counters(item, '.') '. ';
      counter-increment: item;
      padding-right: $baseUnit;
      min-width: $baseUnit * 3.5;
      grid-area: number; }

    > * {
      grid-area: content; } }

  &.list-links {
    li {
      position: relative;
      color: $colourLink;

      &:before {
        color: currentColor;

        &:hover {
          color: $colourLinkHover;
          text-decoration: underline; } }

      a {
        word-break: break-word;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%; } } } } }

@import "list-sm-up";
@import "list-md-up";
@import "list-lg-up";
