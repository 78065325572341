@media #{$breakpoint-md-up} {
  .image {
    &.with-video {
      @include aspect-16-9;

      img {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }

      .video {
        position: absolute;
        top: auto;
        right: #{$imageVideoOffsetSM};
        bottom: #{$imageVideoOffsetSM};
        width: #{$imageVideoWidth};
        height: #{$imageVideoHeight};
        margin-bottom: 0;

        &--active {
          left: calc(100% - #{$imageVideoWidth + $imageVideoOffsetSM}); } } }

    &.with-icon-and-text {
      .image-main {
        .icon {
          @include square($linkIconLGWidth); } } }

    &--downloadable {
      .image {
        &__caption {
          flex-direction: row;

          &__content {
            flex-grow: 1;
            align-self: center;
            margin: 0 ($baseUnit * 2) 0 0; } }

        &__download {
          flex-grow: 0;
          flex-shrink: 0;
          align-self: flex-start; } } } } }
