.nav-section {
  display: none;
  min-width: 100%;
  width: auto;
  background-color: $colourWhite;
  margin: 0 (-$baseUnit*1) $baseUnit*4;
  position: relative;

  &-top {
    min-height: 1px;
    min-width: 1px; }

  &-title {
    color: $colourRed;
    display: none;
    padding: $baseUnit $baseUnit 0 $baseUnit;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

  &-subtitle {
    display: none; }

  .navbar {
    padding: 0;
    overflow: auto;
    border-top: ($baseUnit*0.1) solid $colourGreySoft;
    border-bottom: ($baseUnit*0.1) solid $colourGreySoft;
    margin: 0 - 10px;

    .nav {
      width: 100%;
      margin: 0;
      background-color: $colourWhite;
      flex-wrap: nowrap;
      -ms-overflow-style: none;
      -webkit-overflow-scrolling: touch;
      -ms-touch-action: pan-x;
      -ms-scroll-chaining: none;
      scrollbar-width: none;
      position: relative;

      .nav-item {
        flex: 0 0 auto;
        padding: 0 $baseUnit;
        -ms-overflow-style: none;

        .nav-link {
          color: $colourGreyDarker;
          text-shadow: none;
          background-color: $colourWhite;
          padding: $baseUnit*1.5 0 $baseUnit*1.2;
          margin: 0;
          line-height: 1.25rem;
          text-decoration: none;
          border-bottom: $baseUnit*0.4 solid $colourTransparent;

          &.dropdown-toggle {
            height: auto;
            border: none;
            padding-right: $baseUnit*2; } }

        &:hover,
        &:focus {
          .nav-link {
            border-bottom: $baseUnit*0.4 solid $colourTintPrime;
            color: $colourGreyDarkest;
            box-shadow: none; } }

        &.active {
          .nav-link {
            background-color: $colourWhite;
            box-shadow: none;
            color: $colourRed;
            border-bottom: #{$baseUnit * 0.4} solid $colourRed; } }

        &:first-child {
          padding-left: ($baseUnit * 2); }

        &.last-item {
          padding-right: ($baseUnit * 3.5); }

        &::-webkit-scrollbar {
          width: 0;
          height: 0; } } }

    &:hover,
    &:focus {
      .nav-link {
        background-color: $colourWhite;
        box-shadow: none; } } }

  &:after {
    content: '';
    position: absolute;
    z-index: 2000;
    pointer-events: auto;
    background-image: linear-gradient(to right, $colourTransparent, $colourWhite 100%);
    width: ($baseUnit * 4);
    top: 0;
    bottom: 0;
    right: 0; }

  &.sticky-top {
    display: block;
    position: sticky;
    z-index: 1001;
    border: none;
    margin-bottom: 0;
    top: 0;

    .navbar {
      border-top: 0;
      margin: 0 (-$baseUnit*1);

      .nav {
        width: 100vw; } } } }

@import 'nav-section-sm-up';
@import 'nav-section-md-up';
@import 'nav-section-lg-up';
