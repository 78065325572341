$checkmarkWidth: $baseUnit*1.4;
$checkmarkBorderWidth: $baseUnit*0.35;
$itemGap: $baseUnit*0.4;

.checkbox-btn-group,
.radio-btn-group {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
  grid-area: search-form-radio-group;
  border: $baseUnit*0.1 solid $colourBtnLinkColour;
  width: 100%;
  border-radius: $baseUnit*0.4;
  padding: $itemGap;
  margin-left: 0;
  min-height: $baseUnit*4.3;

  > .checkbox-option,
  > .radio-option {
    margin-bottom: $itemGap;
    flex: 1 1 auto;
    margin-left: 0;

    input[type="checkbox"],
    input[type="radio"] {
      &:focus {
        & + label {
          box-shadow: 0 0 0 $baseUnit*0.3 $colourSweetCorn;

          &:before {
            box-shadow: none; } } }

      + .radio-option-label,
      + .checkbox-option-label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $boldFontFamily;
        text-align: center;
        vertical-align: middle;
        padding: 0.5rem 1rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-bottom: 0;
        height: 100%;
        border: none;
        border-radius: $baseUnit*0.4;
        text-decoration: none;
        color: $colourBtnLinkColour;
        background-color: $colourTransparent;

        &:hover {
          text-decoration: underline; }

        &:after {
          content: none; }

        &:before {
          content: "";
          border-radius: 0;
          position: relative;
          display: inline-block;
          top: 0;
          left: 0;
          width: $checkmarkWidth;
          height: $baseUnit*0.8;
          opacity: 0;
          border: solid;
          border-width: 0 0 $checkmarkBorderWidth $checkmarkBorderWidth;
          border-top-color: transparent;
          transform: rotate(-45deg);
          pointer-events: none;
          margin: #{-$baseUnit*0.4} $baseUnit 0 #{-$checkmarkWidth};
          padding: 0; } }

      &:checked + .radio-option-label,
      &:checked + .checkbox-option-label {
        color: $colourWhite;
        background-color: $colourBtnInfoBackground;
        text-decoration: none;

        &:hover {
          background-color: $colourBtnInfoBackgroundHighlight; }

        &::before {
          opacity: 1; } } }

    &:last-child {
      margin: 0; } }

  &-bordered {
    padding: 0;
    border: none;

    > .checkbox-option,
    > .radio-option {
      margin: 0;

      input[type="checkbox"],
      input[type="radio"] {

        + .radio-option-label,
        + .checkbox-option-label {
          border-radius: 0;
          font-size: 1rem;
          border: $baseUnit*0.1 solid $colourBtnLinkColour;
          border-bottom: 0; } }

      &:first-child {
        input[type="checkbox"],
        input[type="radio"] {
          + .radio-option-label,
          + .checkbox-option-label {
            border-top-left-radius: $baseUnit*0.4;
            border-top-right-radius: $baseUnit*0.4; } } }

      &:last-child {
        input[type="checkbox"],
        input[type="radio"] {
          + .radio-option-label,
          + .checkbox-option-label {
            border-bottom: $baseUnit*0.1 solid $colourBtnLinkColour;
            border-bottom-left-radius: $baseUnit*0.4;
            border-bottom-right-radius: $baseUnit*0.4; } } } } } }

@import 'form-btn-group-xs-up';
@import 'form-btn-group-md-up';
