.skip-content {
  display: inline-block;
  margin: $baseUnit*0.6 0 0 $baseUnit*3;
  background-color: $colourWhite;
  position: absolute;
  left: -9999em;
  top: 0;

  &:focus {
    left: 0px;
    padding: $baseUnit*0.5;
    z-index: 10;
    outline: $baseUnit solid $colourWhite;
    background-color: $colourWhite;
    text-decoration: underline; } }
