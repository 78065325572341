@media #{$breakpoint-lg-up} {
  .nav-secondary {
    order: 1;
    -ms-grid-row: 1;
    display: block;
    background-color: $colourBlack;

    .navbar {
      padding: 0 $baseUnit*1.6;
      max-width: map-get($container-max-widths, 'xl');
      margin: auto; }
    .nav {
      width: 100%;
      margin-bottom: 0;

      .nav-item {
        margin: $baseUnit*1.15 $baseUnit;

        &:last-child {
          margin-right: 0; }

        .nav-link {
          color: $colourTintLight;
          padding: $baseUnit*0.3;
          font-size: map-get($fontSizes, "sm");
          line-height: map-get($lineHeights, "sm");

          .icon {
            fill: $colourWhite;
            margin: 0; }

          &:hover {
            color: $colourWhite; }

          &:focus {
            color: $colourGreyDarker;
            background-color: $colourSweetCorn;
            outline: none;

            .icon {
              margin: 2px 0;
              fill: $colourGreyDarker; } } } } } } }

