@media #{$breakpoint-lg-up} {
  form {
    input,
    .form-control {
      &:focus {
        border-color: $colourGreyDarker;
        outline: none;
        box-shadow: 0 0 0 $baseUnit*0.3 $colourSweetCorn; } } }

  select,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"] {
    &:focus {
      border-color: $colourGreyDarker;
      outline: none;
      box-shadow: 0 0 0 $baseUnit*0.3 $colourSweetCorn; } }

  textarea {
    &:focus {
      outline: none;
      box-shadow: 0 0 0 $baseUnit*0.3 $colourSweetCorn; } } }
