@import "assets/sass/main.sass";

.otpContainer {
    display:flex;
    justify-content: center;
    margin: 5% auto;

    .otpInput {
        width: 2rem !important;
        height: 2rem;
        margin: 0 0.25rem;
        font-size: 0.75rem;
        text-align: center;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        @media (min-width: 768px) {
            width: 3rem !important;
            height: 3rem;
            margin: 0 0.5rem;
            font-size: 2rem;
            text-align: center;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.3);
        }
    }
}
