@mixin margin-utilities {
  @each $class, $value in $utilitySizes {
    @include margin-utilities-item($class, $value);

    @each $breakpoint, $media in $breakpointsForLoop {
      @media #{$media} {
        @include margin-utilities-item('#{$breakpoint}-#{$class}', $value); } } } }

@include margin-utilities;

/* Negative margins */
.-m {
  &b-5 {
    margin-bottom: -$baseUnit*0.5 !important; }

  &b-10 {
    margin-bottom: -$baseUnit !important; }

  &b-15 {
    margin-bottom: -$baseUnit*1.5 !important; }

  &t-5 {
    margin-top: -$baseUnit*0.5 !important; }

  &t-10 {
    margin-top: -$baseUnit !important; }

  &t-15 {
    margin-top: -$baseUnit*1.5 !important; } }
