.tooltip {
  font-size: map-get($fontSizes, 'sm');

  .tooltip-inner {
    @include border-radius(0);
    background: $colourBlack;
    padding: $baseUnit #{($baseUnit * 2)}; }

  &.bs-tooltip-top {
    .arrow {
      &:before {
        border-top-color: $colourBlack; } } }

  &.bs-tooltip-right {
    .arrow {
      &:before {
        border-right-color: $colourBlack; } } }

  &.bs-tooltip-bottom {
    .arrow {
      &:before {
        border-bottom-color: $colourBlack; } } }

  &.bs-tooltip-left {
    margin-left: -#{$baseUnit * 1.2};

    .arrow {
      &:before {
        border-left-color: $colourBlack; } } } }
