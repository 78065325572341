.nav-global-mobile {
  display: none;
  visibility: hidden;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  background-color: #fff;
  transition: all $mobileMenuSlideDuration $mobileManuSlideTiming, $transition-collapse;
  transform: translateX(-100%);
  z-index: 9999;
  height: calc(100% - #{$mobileNavHeight});

  .nav-back {
    transition: transform $mobileMenuSlideDuration $mobileManuSlideTiming;

    .icon {
      pointer-events: none; }

    .nav-link {
      .icon {
        margin-right: $baseUnit; }

      span {
        pointer-events: none; } } }

  .nav-item {
    display: flex;
    flex-direction: row;
    list-style: none;
    font-size: $mobileNavFontSize;
    line-height: $mobileNavLineHeight;
    border-bottom: #{$baseUnit * 0.1} solid $colourSilver;

    .nav-link {
      display: flex;
      flex-basis: 100%;
      align-items: center;
      color: $colourBlack;
      flex-direction: row;
      padding: #{$baseUnit * 1.5} #{$baseUnit * 2};
      min-height: 60px;

      &.active {
        color: $colourRed;
        font-family: $boldFontFamily; } }

    &-expandable {
      .nav-link {
        display: flex;
        flex-basis: calc(100% - 67px);

        &-expand {
          display: flex;
          flex-basis: 67px;
          border-left: #{$baseUnit * 0.1} solid $colourSilver;
          justify-content: center;
          align-content: center;

          .icon {
            margin: $baseUnit*2.5;
            pointer-events: none; } } } }

    .nav-link.active,
    &.nav-back {
      font-family: $boldFontFamily; }

    &.nav-back {
      font-size: $mobileNavBackFontSize;
      line-height: $mobileNavBackLineHeight; } }

  .nav-global-mobile-section {
    background-color: $white;
    display: flex;
    flex-direction: column;

    > .nav-link {
      &.active {
        background-color: $colourRed;
        color: $colourWhite; } }

    .nav-item {
      .nav-link {
        padding-left: #{$baseUnit * 4}; }

      &:last-child {
        border-bottom: none; } } }

  &-next {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translate(100%, 0%);

    &.transitioning {
      transition: transform $mobileMenuSlideDuration $mobileManuSlideTiming;
      transform: translate(0%, 0%); } }
  &-previous {
    &.transitioning {
      transform: translate(-100%, 0);
      transition: transform $mobileMenuSlideDuration $mobileManuSlideTiming; } }

  &-next.back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translate(-100%, 0%);

    &.transitioning {
      transition: transform $mobileMenuSlideDuration $mobileManuSlideTiming;
      transform: translate(0%, 0%); } }

  &-previous.back {
    &.transitioning {
      transform: translate(100%, 0);
      transition: transform $mobileMenuSlideDuration $mobileManuSlideTiming; } }

  > .loading {
    height: calc(100% - 59px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; } }
