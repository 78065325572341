body {
  display: block;
  display: grid;
  flex-direction: column;
  grid-template-areas: "nav-global" "search-global" "breadcrumb" "hero-section" "content" "footer-local" "footer-global";
  grid-template-columns: 100%;

  &.with-site-alert {
    grid-template-areas: "nav-global" "search-global" "breadcrumb" "site-alert" "hero-section" "content" "footer-local" "footer-global"; }

  &.with-cookie-banner {
    grid-template-areas: "cookie-banner" "nav-global" "search-global" "breadcrumb" "hero-section" "content" "footer-local" "footer-global";

    &.with-site-alert {
      grid-template-areas: "cookie-banner" "nav-global" "search-global" "breadcrumb" "site-alert" "hero-section" "content" "footer-local" "footer-global"; } } }

@media #{$breakpoint-lg-up} {
  body {
    grid-template-areas: "nav-secondary" "search-global" "nav-global" "breadcrumb" "hero-section" "content" "footer-local" "footer-global";

    .nav-secondary {
      order: 1;
      -ms-grid-row: 1; }

    &.with-site-alert,
    &.with-cookie-banner {
      .nav-secondary {
        order: 2;
        -ms-grid-row: 2; } }

    &.with-site-alert {
      grid-template-areas: "nav-secondary" "search-global" "nav-global" "breadcrumb" "site-alert" "hero-section" "content" "footer-local" "footer-global"; }

    &.with-cookie-banner {
      grid-template-areas: "cookie-banner" "nav-secondary" "search-global" "nav-global" "breadcrumb" "hero-section" "content" "footer-local" "footer-global";

      &.with-site-alert {
        grid-template-areas: "cookie-banner" "nav-secondary" "search-global" "nav-global" "breadcrumb" "site-alert" "hero-section" "content" "footer-local" "footer-global"; } } } }
