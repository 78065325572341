.contact {
  &-title {
    color: $colourRed;
    font: normal normal $headingFontSize $boldFontFamily;
    margin: 0; }

  .contact-subtitle {
    font-size: map-get($fontSizes, "lg");
    line-height: 1.625rem;
    margin-bottom: $baseUnit*0.5; } }
