@media #{$breakpoint-sm-up} {
  .footer-fixed {
    .footer-fixed {
      &-title {
        font-size: 1.1425rem;
        line-height: 1.375rem; }

      &-contact {
        font-size: 1.25rem;
        line-height: 1.375rem; } } } }
