@media #{$breakpoint-xl-up} {
  .l-home {
    .teaser-homepage {
      .teaser-image {
        a {
          &:focus {
            span {
              background: $colourSweetCorn;
              color: $colourBlack; } } } }

      .teaser-body {
        .teaser-title {
          a {
            &:focus {
              background: $colourSweetCorn;
              text-decoration: none; } } } } } } }
