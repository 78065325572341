/* Import fonts */
@font-face {
  font-family: 'franklin_gothic_fs_bookRg';
  src: url('../../webfonts/FranklinGothic-Book.woff'),
  url('../../webfonts/FranklinGothic-Book.eot'),
  url('../../webfonts/FranklinGothic-Book.ttf'),
  url('../../webfonts/FranklinGothic-Book.svg') {} }

@font-face {
  font-family: 'franklin_gothic_fs_bookitalic';
  src: url('../../webfonts/FranklinGothic-BookIt.woff'),
  url('../../webfonts/FranklinGothic-BookIt.eot'),
  url('../../webfonts/FranklinGothic-BookIt.ttf'),
  url('../../webfonts/FranklinGothic-BookIt.svg') {} }

@font-face {
  font-family: 'franklin_gothic_URW_lig';
  src: url('../../webfonts/FranklinGothicURW-Lig.woff'),
  url('../../webfonts/FranklinGothicURW-Lig.eot'),
  url('../../webfonts/FranklinGothicURW-Lig.ttf'),
  url('../../webfonts/FranklinGothicURW-Lig.svg') {} }

@font-face {
  font-family: 'franklin_gothic_fs_medregular';
  src: url('../../webfonts/FranklinGothic-Med.woff'),
  url('../../webfonts/FranklinGothic-Med.ttf'),
  url('../../webfonts/FranklinGothic-Med.svg') {} }

@font-face {
  font-family: 'franklin_gothic_fs_meditalic';
  src: url('../../webfonts/FranklinGothic-MedIt-webfont.woff'),
  url('../../webfonts/FranklinGothic-MedIt-webfont.eot'),
  url('../../webfonts/FranklinGothic-MedIt-webfont.ttf'),
  url('../../webfonts/FranklinGothic-MedIt-webfont.svg') {} }

@font-face {
  font-family: 'franklin_gothic_fs_demi';
  src: url('../../webfonts/FranklinGothic-Demi.woff'),
  url('../../webfonts/FranklinGothic-Demi.eot'),
  url('../../webfonts/FranklinGothic-Demi.ttf'),
  url('../../webfonts/FranklinGothic-Demi.svg') {} }

body {
  line-height: map-get($lineHeights, 'normal'); }

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin-top: 0;
  margin-bottom: #{$baseUnit * 2.4};
  text-shadow: none; }

html,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-font-smoothing: antialiased; }

h1 {
  font: normal $headingFontSizeXXXL $brandFamily; }

.page-title {
  font: normal $headingFontSizeXXL $brandFamily; }

h2 {
  font: normal $headingFontSizeXL $brandFamily; }

h3 {
  font: normal $headingFontSizeLG $brandFamily; }

h4 {
  font: normal $headingFontSize $boldFontFamily; }

h5 {
  font: normal $headingFontSizeSM $brandFamily; }

h6 {
  font: normal normal $headingFontSizeXS $brandFamily; }

h1, h2 {
  & + .lead {
    margin-bottom: $baseUnit * 3; } }

/* Link styling */
.content,
.footer-local {
  h1, h2, h3, h4, h5, h6 {
    a {
      color: $colourLink;
      text-decoration: underline;

      &:hover {
        color: $colourLinkHover; } } }

  p, li, td {
    a {
      text-decoration: underline;

      &.btn {
        text-decoration: none; } } } }

a {
  text-decoration: none;

  &:hover {
    color: $colourLinkHover; }

  em {
    font-family: $sansFontFamily;
    text-decoration: none; } }

i,
em {
  font-family: $italicFamily;
  font-style: normal; }

b,
strong {
  font-family: $boldFontFamily; }

.brand {
  font-family: $FranklinGothicMed, $sansFontFamily; }

.text-white {
  color: $colourWhite; }

.btn-italic {
  font-family: $sansFontFamily; }

.page-header {
  border-bottom: 0 solid $colourGreyLighter;
  margin: 0;
  padding: 0;

  h1 {
    margin: #{$baseUnit * 3} 0 #{$baseUnit * 4} 0;
    padding: 0; }

  h2 {
    margin: 0 0 $baseUnit 0; }

  h3 {
    border-bottom: 1px solid #d0d0d0;
    padding-bottom: #{$baseUnit * 2}; } }

.lead {
  line-height: map-get($headingLineHeights, 'normal');
  font-size: map-get($headingFontSizes, 'normal'); }

.lead-small {
  font-size: map-get($headingFontSizes, 'smer');
  line-height: map-get($headingLineHeights, 'smaller'); }

.media {
  .caption {
    font-family: $brandFamily;
    background-color: $colourGreyLighter;
    padding: $baseUnit; }

  em {
    font-family: $brandFamily; } }

.smaller {
  font-size: smaller;

  > * {
    line-height: 16px; } }

dl {
  dt {
    dfn {
      font-style: normal; } }

  dd {
    margin-bottom: #{$baseUnit / 2};
    font-style: normal; } }

abbr[title],
acronym[title] {
  cursor: help;
  border-bottom: 1px dotted $colourTintLight; }

.hidden-text {
  display: none;
  font: 0 / 0; }

h1, h2, h3, h4, h5, h6 {
  color: $colourGreyDarker;

  a {
    color: $colourGreyDarker; } }

.page-parent {
  @extend h1;

  color: $colourRed;

  &.sub-section {
    display: none;
    visibility: hidden; } }

.page-section-heading {
  text-align: center;
  margin-bottom: $baseUnit * 4;

  &:after {
    content: '';
    display: block;
    margin: ($baseUnit * 2) auto 0;
    width: 40px;
    height: 4px;
    background-color: $colourRed; } }

@media #{$breakpoint-lg-up} {
  .page-parent {
    &.sub-section {
      display: inline-block;
      visibility: visible;
      font-size: map-get($headingFontSizes, 'xxxl');
      line-height: map-get($headingLineHeights, 'xxxl');
      margin-bottom: $baseUnit * 2.4;

      &:hover {
        text-decoration: none;
        color: $colourRed; }

      &:focus {
        color: $colourRed; } } } }
