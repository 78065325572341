$imageVideoWidth: 205px;
$imageVideoWidthLG: 310px;
$imageVideoHeight: $imageVideoWidth * 0.5625;
$imageVideoHeightLG: $imageVideoWidthLG * 0.5625;
$imageVideoOffsetSM: 20px;
$imageVideoOffsetMD: 30px;

$linkIconWidth: map-get($svgWidths, 'xl');
$linkIconLGWidth: map-get($svgWidths, 'xxl');

img {
  max-width: 100%;
  height: auto;
  border: 0; }

.image {
  margin: 0 0 ($baseUnit * 2);
  display: block;

  .caption {
    background: $colourTintLightest;
    padding: $baseUnit;
    font-size: map-get($fontSizes, 'sm');
    font-family: $brandFamily;
    display: block;
    caption-side: bottom;
    width: 100%; }

  img {
    display: block;

    & + br {
      display: none; } }

  &.full-width-image {
    margin-bottom: 0; }

  &.float-left {
    margin: 0 #{$baseUnit * 2} #{$baseUnit * 2} 0; }

  &.float-right {
    margin: 0 0 #{$baseUnit * 2} #{$baseUnit * 2}; }

  &.with-video {
    position: relative;

    > img {
      display: none; }

    .video {
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: $colourWhite; } }

  &.with-icon-and-text {
    position: relative;

    img {
      display: block;
      width: 100%;
      height: 100%; }

    .image-main {
      width: 100%;
      min-height: $baseUnit * 10;
      z-index: 1;
      background: $colourBlack;
      margin: 0;
      padding: $baseUnit * 2;
      color: $colourWhite;
      display: flex;
      flex-direction: row;

      .icon {
        @include square($linkIconWidth);

        flex-shrink: 0;
        margin-right: $baseUnit * 2; }

      .image-body {
        width: 100%;

        .image-title {
          font-family: $boldFontFamily;
          font-size: map-get($headingFontSizes, 'normal');
          line-height: map-get($headingLineHeights, 'normal');
          margin-bottom: $baseUnit;
          color: $colourWhite;

          a {
            color: $colourWhite;
            text-decoration: underline; } }

        p {
          margin-bottom: $baseUnit; }

        p:last-child, ul:last-child {
          margin-bottom: 0; } } } }

  &--overlay-content {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 100%;

    .image {
      &__wrapper,
      &__content {
        grid-row: 1;
        grid-column: 1;
        align-self: center; }

      &__wrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background-color: rgba($colourWhite, 0.9); }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%; } }

      &__content {
        position: relative;
        padding: $baseUnit * 2;
        z-index: 2; } } }

  &--downloadable {
    .image {
      &__caption {
        display: flex;
        flex-direction: column; }

      &__download {
        margin-bottom: 0; } } } }

a {
  .image {
    margin-bottom: 0;

    .caption {
      display: inline-block;
      color: $body-color; } } }

a[data-toggle='modal'] {
  &:hover {
    > .image img,
    > img {
      filter: brightness(0.93); } }

  > .image img,
  > img {
    transition: filter $veryShortAnimationDuration $standardAnimationCubic;
    width: 100%; } }

.full-width-image-wrapper {
  background-color: $colourGreySofter; }

@import "image-md-down";
@import "image-md-up";
@import "image-lg-up";
