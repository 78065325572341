.bg {
  &-ocean-green {
    background-color: $colourOceanGreen !important;

    .quote-main {
      background-color: $colourOceanGreen !important; } }

  &-cerise {
    background-color: $colourCerise !important;

    .quote-main {
      background-color: $colourCerise !important; } }

  &-affair {
    background-color: $colourAffair !important;

    .quote-main {
      background-color: $colourAffair !important; } }

  &-panache {
    background-color: $colourPanache !important;

    .quote-main {
      background-color: $colourPanache !important; } }

  &-anakiwa {
    background-color: $colourAnakiwa !important;

    .quote {
      &-main {
        background-color: $colourAnakiwa !important; }

      &-author,
      &-source {
        color: darken($colourRed, 5%); } } }

  &-areo-blue {
    background-color: $colourAeroBlue !important;

    .quote-main {
      background-color: $colourAeroBlue !important; } }

  &-mauve {
    background-color: $colourMauve !important;

    .quote-main {
      background-color: $colourMauve !important; } }

  &-wild-sand {
    background-color: $colourWildSand !important;

    .quote-main {
      background-color: $colourWildSand !important; } }

  &-prussian-blue {
    background-color: $colourPrussianBlue !important; } }



.progress {
  .bg {
    &-warning {
      background-color: $colourBtnWarningBackground !important; }

    &-info {
      background-color: $colourBtnInfoBackground !important; } } }
