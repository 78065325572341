@media #{($breakpoint-sm-only)} {
  .search-bar {
    margin: #{(-$baseUnit * 2)} #{(-$baseUnit)} 0 #{(-$baseUnit)};

    .search-body {
      .search-form {
        margin-left: #{($baseUnit * 2)};
        margin-right: #{($baseUnit * 2)};

        input[type="text"]:focus {
          box-shadow: none; } } } } }
