@media #{($breakpoint-xl-up)} {
  .search-bar {
    .search-body {
      input {
        &:focus {
          outline: #{($baseUnit * 0.3)} solid $colourSweetCorn;
          box-shadow: none; } } } }

  .search-results {
    .teaser {
      a {
        &:focus {
          background-color: $colourSweetCorn;
          text-decoration: none;
          box-shadow: 0 0 0 #{($baseUnit * 0.3)} $colourSweetCorn;
          img {
            outline: #{($baseUnit * 0.3)} solid $colourSweetCorn; } } } } }

  .search-body {
    input {
      &:focus {
        outline: #{($baseUnit * 0.3)} solid $colourSweetCorn;
        box-shadow: none; } } } }
