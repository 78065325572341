@media #{$breakpoint-lg-up} {
  .list {
    &.list- {
      a {
        &:focus {
          background-color: $colourSweetCorn;
          outline: none;

          .icon {
            color: $colourBlack; }

          .icon-tile {
            box-shadow: 0 0 0 ($baseUnit * 0.3) $colourSweetCorn; } } } }

    a {
      &:focus {
        outline: none;
        background-color: $colourSweetCorn;
        box-shadow: 0 0 0 ($baseUnit * 0.3) $colourSweetCorn;
        color: $colourBlack; } } } }
