.l-home {
  padding-top: 0;

  .footer-global {
    margin: 0; }

  .content {
    padding-top: 0;
    padding-bottom: 0; }

  .teaser-homepage {
    .teaser-body {
      h1 {
        &.teaser-title {
          font-family: $brandFamily;

          a {
            color: $colourLink;

            &:hover {
              color: $colourLinkHover; } } } }

      p {
        font-family: $brandFamily;
        font-size: map-get($fontSizes, 'normal');
        line-height: map-get($lineHeights, 'normal'); } }

    .teaser-image {
      position: relative;

      a {
        color: $colourWhite;

        &:hover {
          text-decoration: none; } }

      .teaser-category {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: $baseUnit*0.5 $baseUnit;
        background-color: $colourRed;
        text-transform: uppercase;
        font-family: $brandFamily; } }

    &.teaser {
      .teaser-title {
        font-family: $boldFontFamily;
        font-size: map-get($headingFontSizes, 'normal');
        line-height: map-get($headingLineHeights, 'large');
        margin: $baseUnit*1.5 0 $baseUnit*0.5 0; } }

    &.teaser-large {
      .teaser-title {
        margin-top: $baseUnit;
        font-family: $boldFontFamily;
        font-size: map-get($headingFontSizes, 'xl');
        line-height: map-get($headingLineHeights, 'xl'); }

      p {
        margin-top: $baseUnit; } } }

  .home-calltoaction {
    .home-calltoaction-body {
      h1 {
        &.home-calltoaction-title {
          margin-top: $baseUnit*2;
          margin-bottom: $baseUnit;
          font-family: $brandFamily;
          font-size: map-get($headingFontSizes, 'lg');

          a {
            color: $colourLink; } } }

      p {
        font-family: $brandFamily;
        font-size: map-get($fontSizes, 'sm');

        a {
          color: $colourBlack;
          text-decoration: underline; } } } }

  .quote-homepage {
    position: relative;
    margin: -$baseUnit*4 0;
    padding: 0 0 0 0;
    min-height: $baseUnit*38;

    .quote {
      margin: 0;
      border: 0;
      padding-bottom: 0;

      &.with-image {
        padding-left: $baseUnit*10;
        padding-right: $baseUnit*10;

        .btn {
          display: inline-block; } } }

    .quote-image {
      @include clearfix();
      position: absolute;
      min-height: $baseUnit*35;
      width: $baseUnit*30;
      float: right;
      bottom: 0;
      right: $baseUnit*10;
      height: 100%;

      img {
        position: absolute;
        bottom: 0; } }

    blockquote {
      &.quote-body {
        margin-top: $baseUnit*4;
        padding-right: $baseUnit*30;

        p {
          font-family: $boldFontFamily;
          font-size: map-get($headingFontSizes, 'xl');
          line-height: map-get($headingLineHeights, 'xl'); } } }

    figcaption {
      &.quote-author {
        font-family: $boldFontFamily;
        font-size: map-get($headingFontSizes, 'lg');

        a {
          text-decoration: underline;
          color: $colourRed;

          &:hover {
            color: $colourCabaret; } } } }

    .btn {
      margin-top: $baseUnit*2;
      margin-bottom: $baseUnit*6; }

    .opening-quote {
      width: $baseUnit*8;
      height: $baseUnit*6.1;
      position: absolute;
      top: 0;
      left: 0; }

    .closing-quote {
      width: $baseUnit*8;
      height: $baseUnit*6.1;
      position: absolute;
      bottom: 0;
      right: 0; } } }

@import 'home-xs-down';
@import 'home-xs-only';
@import 'home-sm-only';
@import 'home-md-only';
@import 'home-md-up';
@import 'home-lg-only';
@import 'home-xl-up';
