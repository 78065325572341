@media #{$breakpoint-lg-only} {
  .facet {
    .facet-heading {
      .facet-title {
        &:focus {
          outline: $baseUnit*0.3 solid $colourSweetCorn; } } }

    .facet-items {
      .facet-item {
        a {
          &:focus {
            box-shadow: 0 0 0 $baseUnit*0.3 $colourSweetCorn;
            background-color: $colourSweetCorn;
            outline: none; } } } }

    .facet-show-more {
      &:focus {
        background-color: $colourSweetCorn;
        box-shadow: 0 0 0 $baseUnit*0.3 $colourSweetCorn; } } } }
