body.menu-open {
  position: fixed;
  width: 100%;
  overflow: hidden;


  .nav-global {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $colourWhite;

    &-mobile {
      transform: translateX(0); } } }

body.menu-closing {
  .nav-global-mobile {
    transform: translateX(-100%); } }

body.search-open {
  .nav-global-mobile {
    transition: all $mobileMenuSlideDuration $mobileManuSlideTiming, $transition-collapse;
 } }    // height: calc(100% - #{$mobileNavHeight +$baseUnit*8}) !important


