.pagination {
  @extend .justify-content-center;
  font-family: $boldFontFamily;
  flex-wrap: wrap;

  .page-item {
    display: none;


    &.active,
    &:last-child,
    &:first-child {
      display: block; }

    &:last-child,
    &:first-child {
      @include border-radius(0);
      background-color: $colourGreyLighter;

      .icon {
        @extend .icon-xs;
        margin: 0.53125rem;
        fill: $colourGreyLight; }

      a {
        background: $colourRed;
        padding: 0.40625rem 0.53125rem;
        display: flex;
        align-items: center;
        justify-items: center;
        text-decoration: none;
        min-height: 30px;

        .icon {
          margin: 0;
          fill: $colourWhite;
          display: block;
          margin-right: 0; } } }

    &:first-child {
      margin-right: ($baseUnit * 2); }

    &:last-child {
      margin-left: ($baseUnit * 2); }

    &.disabled {
      .page-link {
        background-color: $colourGreyLighter;

        .icon {
          fill: $colourGreyLight; } } }

    .page-link {
      text-decoration: none;

      .icon {
        @extend .icon-xs;
        fill: $colourWhite;
        display: block;
        margin-right: 0; } } } }

@import 'pagination-sm-up';
@import 'pagination-lg-up';
