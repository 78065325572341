@media #{$breakpoint-lg-only} {
  .footer-local {
    .footer-local-subtitle {
      a {
        &:focus {
          background-color: $colourSweetCorn;
          color: $colourBlack;
          text-decoration: none; }

        &:hover {
          color: $colourLinkHover; } } }

    .list-icons {
      a {
        &:focus {
          box-shadow: 0 0 0 ($baseUnit*0.3) $colourSweetCorn; } } } } }
