@media #{$breakpoint-lg-up} {
  .btn {
    &:focus {
      @include focus-box-shadow(); }

    &-within-input,
    &-within-input-left {
      display: flex;

      &:focus {
        top: $baseUnit*0.5;
        bottom: $baseUnit*0.5;
        background-color: $colourTransparent; } }

    &-within-input {
      &:focus {
        right: $baseUnit*0.5;
        padding-right: $baseUnit; } }

    &-within-input-left {
      &:focus {
        padding-left: $baseUnit;
        left: $baseUnit*0.5; } } } }
