$baseUnit: 	0.625rem;

@import "globals/vars/colour";
@import "globals/vars/type";
@import "globals/vars/icon";
@import "globals/vars/animation";
@import "globals/vars/z-index";
@import "globals/bootstrap-config";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

@import "globals/vars/layout";

// Global files
@import "globals/mixin";
@import "globals/icon";
@import "globals/vars/layout";
@import "globals/layout";
@import "globals/normalise";
@import "globals/type";

// Utilities
@import "globals/utility/background";
@import "globals/utility/border";
@import "globals/utility/display";
@import "globals/utility/margin";
@import "globals/utility/padding";
@import "globals/utility/position";
@import "globals/utility/ratio";
@import "globals/utility/typography";

// Focus state
@import "components/focus/focus";

// navigation
@import "components/nav-global/nav-global";
@import "components/nav-mobile/nav-mobile";
@import "components/nav-section/nav-section";
@import "components/nav-secondary/nav-secondary";
@import "components/nav-local/nav-local";
@import "components/nav-filter/nav-filter";

// Footer
@import "components/footer-global/footer-global";
@import "components/footer-fixed/footer-fixed";
@import "components/footer-local/footer-local";

//Crosss-site
@import "components/button/button";

// Search
@import "components/search/search";
@import "components/search-box/search-box";

// General
@import "components/form/form";
@import "components/form-btn-group/form-btn-group";
@import "components/tooltip/tooltip";
@import "components/list/list";
@import "components/image/image";
@import "components/skip-content/skip-content";
@import "components/contact/contact";
@import "components/alert/alert";
@import "components/dropdown/dropdown";
@import "components/search/search";
@import "components/pagination/pagination";
@import "components/modal/modal";
@import "components/facet/facet";

// Layouts
@import "layouts/header/header";
@import "layouts/open-navigation";
@import "layouts/home/home";

// Roles
@import "roles/feature/feature";
@import "roles/document/document";
