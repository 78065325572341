$baseUnit: 	0.625rem;
$container-max-widths:              ("sm": 100%,  "md": 744px,  "lg": 960px,  "xl": 1190px);
$grid-breakpoints:                  ("xs": 0, "sm": 480px, "md": 768px, "lg": 980px, "xl": 1200px);

/* Colours override */
$red:                               $colourRed;
$green:                             $colourGreen;
$body-color:                        $colourText;
$border-radius:                     0.125rem;
$border-color:                      $colourGreyLighter;
$font-size-base:                    1.0625rem;
$primary:                           $red;
$link-color:                        $colourLink;
$link-color-hover:                  $colourLinkHover;
$grid-gutter-width:                 20px;
$hr-border-width:                   2px;
$tooltip-opacity:                   .8;
$nav-pills-border-radius:           0.125rem;
$nav-link-padding-y:                0.5rem;
$nav-link-padding-x:                0.75rem;

/* Font config */
$font-family-sans-serif:            "franklin_gothic_fs_bookRg", Arial, "Helvetica Neue", sans-serif;
$lead-font-size:                    map-get($headingFontSizes, "normal");
$lead-font-weight:                  400;

/* Font styling */
$font-weight-bold:                  400;
$font-weight-bolder:                400;

/* Alert styling */
$alert-border-radius:               0;
$alert-border-width:                0;

$alert-padding-y:                   0.625rem;
$alert-padding-x:                   0.9375rem;

/* Alert configuration */
$alert-colors:                      "warning" $colourAlertWarningBackground transparent $colourGreyDarker, "error" $colourRed transparent $colourWhite, "info" $colourInfo transparent $colourWhite, "success" $colourSuccess transparent $colourWhite;

/* Button configuration */
$btn-colours:                       "primary" $colourBtnPrimaryBackground $colourWhite $colourWhite transparent $colourBtnPrimaryBackgroundHighlight, "info" $colourBtnInfoBackground $colourWhite $colourWhite transparent $colourBtnInfoBackgroundHighlight, "success" $colourBtnSuccessBackground $colourWhite $colourWhite transparent $colourBtnSuccessBackgroundHighlight, "warning" $colourBtnWarningBackground $colourGreyDarker $colourGreyDarker transparent $colourBtnWarningBackgroundHighlight, "inverse" $colourBtnInverseBackground $colourGreyDarker $colourWhite transparent $colourBtnInverseBackgroundHighlight, "danger" $colourBtnDangerBackground $colourRed $colourWhite  transparent $colourBtnDangerBackgroundHighlight;
$body-color:                        $colourGreyDarker;
$btn-font-family:                   $boldFontFamily;
$btn-padding-y:                     0.5rem;
$btn-padding-x:                     1rem;
$btn-font-size-lg:                  1.3125rem;
$btn-border-radius-lg:              $border-radius;
$btn-padding-y-lg:                  0.34375rem;
$btn-focus-box-shadow:              0 0 0 #{$baseUnit * 0.3} $colourSweetCorn;
$btn-bg-colour:                     $colourBtnBackground;
$btn-bg-colour-hover:               $colourBtnBackgroundHighlight;

/* Badge Overrides */
$badge-padding-y:                   $baseUnit*0.2;

/* Pagination */
$pagination-hover-color:            $colourGreyDarker;
$pagination-hover-bg:               $colourGreyLight;
$pagination-active-bg:              $colourBlack;
$pagination-border-width:           0rem;
$pagination-padding-y:              0.40625rem;
$pagination-padding-x:              0.75rem;
$pagination-line-height:            1;
$pagination-color:                  $colourGrey;

/* Progress */
$progress-height:                   1.5rem;
$progress-bg:                       $colourGreySofter;

/* hr */
$hr-border-color:                   $colourTintLighter;
$hr-border-width:                   0.0625rem;
$hr-margin-y:                       1.5rem;

/* Carousel */
$carousel-caption-color:            $colourText;

/* Tabs */
$nav-tabs-border-color:             $colourGreyLight;
$nav-tabs-border-radius:            0;
$nav-tabs-link-hover-border-color:  $colourGreyLight;
$nav-tabs-link-active-color:        $colourBlack;
$nav-tabs-link-active-border-color: $colourRed $colourGreyLight $colourWhite $colourGreyLight;

/* Card */
$card-border-color:                 $colourTransparent;
$card-border-radius:                0;
$carousel-caption-color:            $colourText;
$card-cap-bg: $colourWhite;


/* breadcrumb */
$breadcrumb-padding-y:              $baseUnit*1.4;
$breadcrumb-padding-x:              0;
$breadcrumb-divider:                none;
$breadcrumb-bg:                     $colourWhite;
$breadcrumb-active-color:           $colourGreyDarker;
$breadcrumb-border-radius:          0;

/* Modal */
$modal-content-border-width:        0;
$modal-content-border-radius:       0;
$modal-header-padding:          $baseUnit*2;
$modal-header-border-width:     $baseUnit*0.1;
$modal-header-border-color:     $colourSilver;
$modal-title-line-height:       $baseUnit * 3;
$modal-fade-transform:          translate(0, 0);
$modal-transition:              opacity .1s linear;

/* table */
$table-border-color:            $colourGreyLight;
$table-cell-padding:            $baseUnit*0.8;
$table-border-width:            $baseUnit*0.1;
$table-accent-bg:               $colourGreyLighter;

/* Form */
$input-border-color:            $colourGreyDarker;
$input-border-width:            $baseUnit*0.2;
$input-border-radius:           $baseUnit*0.4;

$input-padding-y:               $baseUnit*0.675;
$input-padding-x:               0.9375rem;

$input-padding-y-sm:            $baseUnit*0.5;
$input-padding-x-sm:            $baseUnit*0.5;

$input-padding-y-lg:            $baseUnit*0.5;
$input-padding-x-lg:            $baseUnit*0.5;

/* Form states */
$form-feedback-margin-top:      0;
$form-feedback-font-size:       1rem;
$form-feedback-valid-color:     $colourGreyDarker;
$form-feedback-invalid-color:   $colourGreyDarker;

$form-feedback-icon-valid-color: $colourTransparent;
$form-feedback-icon-valid:      "";
$form-feedback-icon-invalid-color: $colourTransparent;
$form-feedback-icon-invalid:    "";

$input-focus-border-color:      $colourBlack;
$input-focus-box-shadow:        $focus-box-shadow;

/* Dropdown * *////
$zindex-dropdown:               1040;
$dropdown-padding-y:            0;
$dropdown-border-color:         $colourGreyDarker;
$dropdown-link-color:           $colourGreyDarker;
$dropdown-divider-bg:           $colourGreyDarker;
$dropdown-divider-margin-y:     0;

$dropdown-link-active-bg:       $colourGreySofter;
$dropdown-link-active-color:    $colourGreyDarker;

$dropdown-item-padding-y:       .5rem;
$dropdown-item-padding-x:       1rem;
