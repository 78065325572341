@media #{$breakpoint-md-up} {
  .alert {
    &-site {
      .btn {
        margin-top: 0; } }

    &-fixed {
      width: auto;
      bottom: $baseUnit * 2;
      left: $baseUnit * 2; }

    &.with-icon {
      .alert-icon {
        width: $baseUnit * 7.5;

        .icon {
          width: $baseUnit * 4.5; } } } } }
