.content {
  @include clearfix();
  padding: $baseUnit*2 $baseUnit;
  position: relative;
  overflow-wrap: break-word;
  order: 5;
  grid-area: content;
  background-color: $colourWhite;

  .map-large {
    margin-bottom: $baseUnit*4; } }

@media #{$breakpoint-md-up} {
  .content {
    padding: $baseUnit*4 0 $baseUnit*2 0; } }
