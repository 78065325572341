.facet {
  border: $baseUnit*0.1 solid $colourGreyLighter;
  margin-bottom: $baseUnit*2;

  .facet {
    &-heading {
      background: $colourGreyLightest; }

    &-title {
      font-family: $boldFontFamily;
      font-size: map-get($headingFontSizes, 'normal');
      line-height: map-get($lineHeights, 'largest');
      padding: $baseUnit*1.5 $baseUnit*2;
      color: $colourBlack;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &:hover,
      &:focus {
        text-decoration: none;
        color: $colourBlack; }

      .icon {
        fill: $colourTapa;
        flex-shrink: 0; } }

    &-body {
      padding: $baseUnit*2 $baseUnit*2 $baseUnit;

      .form-controls {
        margin-top: 0; } }

    &-items {
      padding: 0;
      margin: 0;
      list-style: none;

      .facet-item {
        margin-bottom: $baseUnit*2;

        a {
          display: block;
          text-decoration: none;
          text-indent: -44px;
          padding-left: $baseUnit*4.4;
          color: $colourGreyDarker;


          .icon {
            @include square($iconSize30);
            margin-right: $baseUnit*1.2; }

          .badge {
            &.badge-facet {
              background: $colourRed;
              color: $colourWhite;
              float: right;
              margin-left: ($baseUnit / 2); } } }

        &:hover {
          text-decoration: underline; }

        .icon {
          color: $colourDustyGrey;
          margin-right: $baseUnit*0.5; } } } }

  &.facet-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $colourWhite;
    z-index: 10030;
    margin-bottom: 0;
    border: none;

    .facet-heading {
      background: $colourBlack;

      .facet-title {
        color: $colourWhite;
        margin-bottom: 0;

        .icon {
          float: right;
          cursor: pointer;
          fill: $colourWhite; } } }

    .facet-body {
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0;
      position: absolute;
      top: 59px;
      left: 0;
      right: 0;
      bottom: 74px;

      form {
        margin: 0;
        padding: $baseUnit*1.5 $baseUnit*2 $baseUnit;

        .form-controls {
          margin-top: 0; } }

      .facet-actions {
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        justify-content: center;
        padding: $baseUnit*2 0 $baseUnit;

        button {
          width: 40%;
          margin: 0 $baseUnit 0 0; } }

      .facet-items {
        padding: 0;
        margin-bottom: $baseUnit*2;

        .facet-group-title {
          display: inline-block;
          width: 100%;
          font-size: 1rem;
          margin: 0px;
          white-space: normal;
          border-bottom: $baseUnit*0.1 solid $colourGreyLighter;
          color: $colourLink;

          a {
            display: block;
            padding: $baseUnit*2 0 $baseUnit*2 $baseUnit*2;
            text-decoration: none;

            &:hover {
              text-decoration: none; } } }

        .facet-item {
          margin-bottom: 0;
          color: $colourLink;
          border-bottom: $baseUnit*0.1 solid $colourGreyLighter;
          white-space: nowrap;
          padding: 0 0 0 $baseUnit*2;

          &:hover {
            text-decoration: none; }

          label {
            display: inline-block;
            width: 100%;
            font-size: 1rem;
            padding: $baseUnit*2 0 $baseUnit*2 $baseUnit*0.5;
            margin: 0px;
            white-space: normal;
            font-family: $brandFamily;
            cursor: pointer;

            &::after {
              color: $colourGreyDarker; } }

          input {
            margin: 0; }

          a {
            display: inline-block;
            width: 100%;
            font-size: 1rem;
            padding: $baseUnit*2 0 $baseUnit*2 $baseUnit*0.5;
            margin: 0px;
            white-space: normal;
            text-indent: 0;

            .badge {
              margin-right: $baseUnit*2; } } } } } }

  input[type='date'] {
    margin-bottom: $baseUnit*2.4; }

  .facet-show-more {
    margin-bottom: $baseUnit;
    display: block;
    font-family: $brandFamily; } }

.selected-facets {
  list-style: none;
  margin: 0;
  display: inline-block;

  li {
    margin-bottom: $baseUnit;
    float: left;
    margin-right: $baseUnit;

    .btn {
      text-align: left;
      margin-bottom: 0; } } }

@import 'facet-lg-only';
