@media #{$breakpoint-sm-up} {
  .list {
    &.list-icons {
      &.icons-lg {
        .icon {
          @include square(map-get($svgWidths, 'lg')); } }

      &:not(.list-horizontal) {
        &.icons-lg {
          li {
            padding-left: map-get($svgWidths, 'lg') + 10px;
            text-indent: -#{map-get($svgWidths, 'lg') + 10px}; } } } } } }
