@media #{$breakpoint-md-down} {
  .search-box {
    .green-bg {
      display: none; }

    .call-to-action {
      margin: #{($baseUnit*2)} #{(-$baseUnit * 2)} #{(-$baseUnit*2)};
      padding: ($baseUnit*3) 0;
      background: linear-gradient(90deg, rgba(0, 166, 84, 1) 0%, rgba(73, 159, 127, 1) 100% );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00A654', endColorstr='#499F7F', GradientType=1); } } }
