@media #{$breakpoint-xs-down} {
  .search-box {
    &.overlay-top {
      margin-top: 0; }

    .search-related {
      li {
        float: none;
        border-right: none;
        padding: 0;
        border: none;

        &:last-child {
          padding: 0; } } }

    .search-title-options {
      width: 100%;

      select {
        width: 100%; } } }

  .search-box-form {
    .search-input {
      &.input-with-btn::placeholder {
        font-size: (map-get($fontSizes, 'sm')); } } } }
