.modal {
  position: fixed;
  top: 0;
  outline: 0;
  z-index: 1041;
  background-color: $colourTransparent;
  max-height: none;
  padding: 0 !important;
  border: none;

  &-content {
    min-height: 100vh;
    background-clip: border-box; }

  &-dialog {
    position: relative;
    margin: 0;
    z-index: 1051;
    max-width: none;
    width: 100%; }

  &-header {
    @extend .position-sticky;

    padding: #{$baseUnit * 0.5} $baseUnit #{$baseUnit * 0.5} #{$baseUnit * 2};
    top: 0;
    align-items: center;
    z-index: 9999;
    background-color: $colourWhite;

    .modal-title {
      font-family: $boldFontFamily;
      font-size: map-get($headingFontSizes, 'smer');
      margin: 0; }

    .close {
      margin: 0;
      padding: $baseUnit * 1.5;
      font-size: 0;
      justify-self: end;

      &:not(.disabled):not(.disabled) {
        opacity: 1; }

      .icon {
        margin-right: 0; } }

    &.no-title {
      justify-content: flex-end; } }

  &-summary {
    background-color: $colourGreyDarker;
    color: $colourWhite;
    padding: #{$baseUnit * 4} $baseUnit * 2.5 #{$baseUnit * 3} #{$baseUnit * 2};

    .table,
    h2 {
      color: $colourWhite; } }

  &-body {
    position: relative;
    flex: 1 1 auto;
    padding: #{$baseUnit * 2.5} $baseUnit * 2.5 #{$baseUnit * 2.5} #{$baseUnit * 2};
    max-height: none;
    background-color: $colourWhite;

    .image {
      margin: 0;

      &.float-left {
        margin: 0 ($baseUnit * 2) ($baseUnit * 2) 0; }

      &.float-right {
        margin: 0 0 ($baseUnit * 2) ($baseUnit * 2); } } }

  &-form {
    margin-bottom: 0; }

  &-facet {
    border: none;

    .modal {
      &-body {
        padding-bottom: 0;

        form {
          margin-bottom: 0;

          .form {
            &-label {
              font-size: map-get($fontSizes, 'lg');

              & + .form-text {
                margin-top: -$baseUnit * 1.6; } }

            &-buttons {
              padding: $baseUnit * 2;
              margin: $baseUnit * 3 #{-$baseUnit * 2} 0 #{-$baseUnit * 2};
              display: flex;
              flex-direction: column;
              border-top: ($baseUnit * 0.1) solid $colourMercury;
              background-color: $colourWhite;

              .btn {
                margin-bottom: $baseUnit;

                &:last-child {
                  margin-bottom: 0; } } } } } } } }

  &-image {
    width: 100%;
    overflow: visible;

    .modal {
      &-dialog {
        @extend .modal-dialog-centered;

        max-width: 90%;
        margin: 0 auto; }

      &-body,
      &-content,
      &-header {
        background-color: $colourTransparent; }

      &-header {
        justify-content: flex-end;
        border: none;

        .close-modal {
          background-color: $colourTransparent;
          border: none;
          color: $colourWhite;
          display: flex;
          align-items: center;
          padding: $baseUnit;
          margin-right: $baseUnit * 2;

          .icon {
            margin-left: $baseUnit;
            margin-right: 0;
            fill: $colourWhite; } } }

      &-content {
        min-height: unset;

        img {
          width: 100%; }

        .image {
          &__caption {
            font-size: map-get($font17, 'size');
            line-height: map-get($font17, 'line-height');
            padding: $baseUnit * 2 #{$baseUnit * 3};

            &__content {
              margin-bottom: 0; } } } }

      &-body {
        padding: 0; } } } }

@import "modal-md-up";
@import "modal-lg-up";
@import "modal-xl-up";
