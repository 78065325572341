@media #{$breakpoint-lg-up} {
  li > a:focus {
    outline: #{$baseUnit * 0.3} solid $colourSweetCorn; }

  p > a:focus,
  a:focus {
    @include focus-state(); }

  .masthead-title > a:focus, .masthead-subtitle > a:focus {
    color: $colourBlack; }

  .nav-local {
    li > a:focus {
      outline: #{$baseUnit * 0.3} solid $colourSweetCorn; } }

  a:focus > img {
    box-shadow: 0 0 0 #{$baseUnit * 0.3} $colourSweetCorn; } }
