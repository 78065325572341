.dropdown {
  &-toggle {
    border: $baseUnit*0.2 solid $colourGreyDarker;
    background: $colourWhite;
    white-space: normal;
    text-align: left;
    padding: $baseUnit*.7 $baseUnit*3 $baseUnit*.7 $baseUnit*1.6;

    &:hover,
    &:focus {
      background: $colourWhite; }

    &:focus {
      outline: none; }

    &:after {
      position: absolute;
      right: $baseUnit*1.5;
      top: 45%; } }

  &-menu {
    .dropdown {
      &-divider {
        + .dropdown-item {
          border-top: 0; } }

      &-item {
        text-decoration: none;
        white-space: normal;
        border-top: $baseUnit*0.1 solid $colourPrimeGrey;

        &:first-child {
          border-top: 0; }

        &:hover {
          text-decoration: underline; } } } } }
