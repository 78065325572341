@media #{$breakpoint-xl-only} {
  .search-box {
    .search-box-form {
      .search-input {
        &:focus {
          outline: ($baseUnit*0.3) $colourSweetCorn solid; } }

      .search-submit {
        &:focus {
          border: ($baseUnit*0.3) solid $colourSweetCorn;
          outline: none; } } }

    .search-related {
      &.list-links {
        li {
          a {
            &:focus {
              background-color: $colourSweetCorn;
              color: $colourBlack;
              outline: none; } } } } }

    .search-title-options {
      select {
        &:focus {
          outline: ($baseUnit*0.3) $colourSweetCorn solid; } } }

    .call-to-action {
      h4 {
        a {
          &:focus {
            color: $colourBlack;
            background-color: $colourSweetCorn;
            border: ($baseUnit*0.3) solid $colourSweetCorn; } } }

      .call-to-action-link {
        &:focus {
          color: $colourBlack;
          background-color: $colourSweetCorn;
          text-decoration: none;

          .icon {
            fill: $colourBlack; } } } } } }
