@media #{$breakpoint-lg-up} {
  .footer-fixed {
    padding: #{$baseUnit * 3.5} #{$baseUnit * 2};

    .footer-fixed {
      &-contact,
      &-title {
        font-size: 1.5rem;
        line-height: 1.75rem; }

      &-contact {
        .icon {
          @include square(map-get($svgWidths, 'md') + 4); }

        &:focus {
          color: $colourBlack; } } } } }
