.icon {
  @include square(map-get($svgWidths, "sm"));
  fill: $colourGreyDarker;
  margin-right: $baseUnit / 2;

  &-xs {
    @include square(map-get($svgWidths, "xs")); }

  &-md {
    @include square(map-get($svgWidths, "md")); }

  &-lg {
    @include square(map-get($svgWidths, "lg")); }

  &-xl {
    @include square(map-get($svgWidths, "xl")); }

  &-success {
    fill: $colourSuccess; }

  &-error {
    fill: $colourRed; }

  &-info {
    fill: $colourInfo; }

  &-tile {
    fill: $colourWhite;
    padding: $baseUnit * 0.7; }

  &.loading {
    @include square(50px);
    stroke: $colourGreyDarker;

    .loading-circle {
      stroke: $colourGreyLight; } }

  &-dark {
    fill: $colourGreyDarker; }

  &-light {
    fill: $colourWhite; }

  .fill-gold {
    fill: $colourHusk; } }
