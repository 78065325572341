.btn {
  display: block;
  margin-bottom: $baseUnit * 2;

  .icon {
    @include square(map-get($svgWidths, 'sm'));

    display: inline-block;
    margin: 0 #{$baseUnit * 0.7} 0 -#{$baseUnit * 0.2}; }

  &.icon-right {
    .icon {
      margin: 0 -#{$baseUnit * 0.2} 0 #{$baseUnit * 0.7}; } }

  &.btn-filter {
    margin-bottom: $baseUnit * 2;

    .icon {
      @include square($iconSize20);

      margin: 0 0 0 ($baseUnit * 0.7); } }

  &-outline {
    color: $colourBlack;
    background-color: $colourTransparent;
    background-image: none;
    border: solid #{$baseUnit * 0.1} $colourGrey;

    &:focus,
    &:active,
    &:hover,
    &.active {
      background-color: $colourTransparent; }

    &.disabled {
      &:focus,
      &.focus {
        border-color: $colourGreyLight; } }

    &-link {
      border: ($baseUnit * 0.1) solid $colourBtnLinkColourHover;
      color: $colourBtnLinkColour;
      background-color: $colourBtnLinkBackground;

      &:hover,
      &:focus {
        background-color: $colourBtnLinkBackground; } } }

  &-link {
    color: $colourBtnLinkColour;
    background-color: $colourBtnLinkBackground;

    &:hover {
      color: $colourBtnLinkColourHover;
      background-color: $colourBtnLinkBackgroundHover; }

    &:focus {
      color: $colourBtnLinkColour;
      background-color: $colourBtnLinkBackgroundHover; } }

  &-group {
    padding: 0;

    &-title {
      margin-right: $baseUnit * 2;
      font-family: $boldFontFamily;
      color: $colourBlack;
      margin-bottom: $baseUnit * 0.5;
      text-transform: uppercase; } }

  &.btn-content-toggle {
    display: block;
    margin: 3px auto 0;

    .icon {
      transition: transform $standardAnimationCubic $standardAnimationDuration; }

    &.active {
      .icon {
        transform: rotate(180deg); } } }

  &-toolbar {
    margin-top: 0;
    display: inline-block;
    margin-bottom: $baseUnit * 2;

    &.no-margin-bottom {
      margin-bottom: 0; } }

  &-within-input,
  &-within-input-left {
    display: flex;
    position: absolute;
    top: $baseUnit * 0.3;
    bottom: $baseUnit * 0.3;
    background-color: $colourWhite;

    &:focus,
    &:hover {
      background-color: $colourWhite; }

    .icon {
      margin-right: 0; } }

  &-within-input {
    display: flex;
    position: absolute;
    right: $baseUnit * 0.3;

    ~ input[type='text'],
    ~ input[type='password'],
    ~ input[type='number'],
    ~ input[type='email'],
    ~ input[type='url'],
    ~ input[type='search'],
    ~ input[type='tel'] {
      padding-right: 40px; } }

  &-within-input-left {
    left: $baseUnit * 0.3;

    ~ input[type='text'],
    ~ input[type='password'],
    ~ input[type='number'],
    ~ input[type='email'],
    ~ input[type='url'],
    ~ input[type='search'],
    ~ input[type='tel'] {
      padding-left: 40px; } }

  &[type='submit'] {
    appearance: none; } }

@import "button-md-up";
@import "button-lg-up";
