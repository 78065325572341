$breakpointsForLoop: ('sm': $breakpoint-sm-up, 'md': $breakpoint-md-up, 'lg': $breakpoint-lg-up, 'xl': $breakpoint-xl-up);

@function calc-rem($size) {
  @return #{$size / 16}rem; }

@mixin font-face($style-name, $family) {
  $filepath: '../webfonts/' + $family;

  @font-face {
    font-family: '#{$style-name}';
    src: url($filepath + '.eot'), url($filepath + '.eot?#iefix') format('embedded-opentype'), url($filepath + '.woff') format('woff'), url($filepath + '.ttf') format('truetype'), url($filepath + '.svg#' + $style-name + '') format('svg');
    font-weight: 400; } }

@mixin focus-state {
  background-color: $colourSweetCorn;
  text-decoration: none;
  outline-offset: 0;
  outline: 3px solid $colourSweetCorn;
  color: $colourLink; }

@mixin aspect-16-9 {
  padding-top: 56.25%; }

@mixin bg-colour($colour) {
  background-color: #{$colour}; }

@mixin focus-box-shadow {
  outline: none;
  box-shadow: 0 0 0 #{$baseUnit * 0.3} $colourSweetCorn; }

%antialiasing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

%transition {
  transition: transform 0.15s ease-in; }

%rotate-0 {
  transform: rotate(0deg); }

%rotate-90 {
  transform: rotate(90deg); }

%rotate-180 {
  transform: rotate(180deg); }

%rotate-270 {
  transform: rotate(270deg); }

%no-border {
  border: none; }

@mixin square($size) {
  width: $size;
  height: $size; }

@mixin flex($values) {
  flex: $values; }

@mixin border-radius($radius: $border-radius) {
  @if $enable-rounded {
    border-radius: $radius; } }

@mixin clearfix {
  &::after,
  &::before {
    display: block;
    clear: both;
    content: ''; } }

@mixin content-columns($columnCount, $columnGap: $grid-gutter-width) {
  column-count: $columnCount;
  column-gap: $columnGap; }

@mixin grid-ms-fallback($col, $row) {
  -ms-grid-column: $col;
  -ms-grid-row: $row; }

@mixin card-control-item {
  cursor: pointer;
  opacity: 0;

  .icon {
    @include square(45px);

    background-color: $colourGreyDarker;
    fill: $colourWhite;
    border-radius: 50%;
    margin-right: 0; }

  &.disabled {
    opacity: 0.5 !important;
    cursor: not-allowed; }

  &.controls-item-prev,
  &.carousel-control-prev {
    .icon {
      padding: ($baseUnit * 1.2) ($baseUnit * 1.3) ($baseUnit * 1.2) ($baseUnit * 1.1); } }

  &.controls-item-next,
  &.carousel-control-next {
    .icon {
      padding: ($baseUnit * 1.2) ($baseUnit * 1.1) ($baseUnit * 1.2) ($baseUnit * 1.3); } }

  @media #{$breakpoint-lg-up} {
    &:focus {
      background-color: transparent;
      outline: none;

      &:not(.disabled) {
        .icon {
          box-shadow: 0 0 0 #{$baseUnit * 0.3} $colourSweetCorn; } } } } }

@mixin global-nav-active-arrow {
  &:after,
  &:before {
    bottom: -$baseUnit * 2.7;
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    z-index: 9999; }

  &:before {
    left: calc(50% - 12px);
    border-color: $colourTransparent $colourTransparent $colourGreyDarkest $colourTransparent;
    border-width: 11px; }

  &:after {
    left: calc(50% - 11px);
    border-color: $colourTransparent $colourTransparent $colourWhite $colourTransparent;
    border-width: 10px; } }

@mixin local-nav-level-padding {
  @for $i from 1 through 6 {
    .level-#{$i} {
      padding-left: #{$i * 18.5}px !important;

      @if $i == 2 {
        background-color: $colourGreySofter;

        &:hover {
          color: $colourGreyDarkest; } }
      @else {
        background-color: $colourWhite; } } } }

@mixin margin-utilities-item($class, $value) {
  .ml-#{$class} {
    margin-left: $value !important; }

  .mr-#{$class} {
    margin-right: $value !important; }

  .mt-#{$class} {
    margin-top: $value !important; }

  .mb-#{$class} {
    margin-bottom: $value !important; }

  .my-#{$class} {
    margin-top: $value !important;
    margin-bottom: $value !important; }

  .mx-#{$class} {
    margin-left: $value !important;
    margin-right: $value !important; } }

@mixin padding-utilities-item($class, $value) {
  .pl-#{$class} {
    padding-left: $value !important; }

  .pr-#{$class} {
    padding-right: $value !important; }

  .pt-#{$class} {
    padding-top: $value !important; }

  .pb-#{$class} {
    padding-bottom: $value !important; }

  .py-#{$class} {
    padding-top: $value !important;
    padding-bottom: $value !important; }

  .px-#{$class} {
    padding-left: $value !important;
    padding-right: $value !important; } }

@mixin width-percentages-item($breakpoint) {
  .w#{$breakpoint}-1 {
    width: 8.333333%; }

  .w#{$breakpoint}-2 {
    width: 16.666667%; }

  .w#{$breakpoint}-3 {
    width: 25%; }

  .w#{$breakpoint}-4 {
    width: 33.333333%; }

  .w#{$breakpoint}-5 {
    width: 41.666667%; }

  .w#{$breakpoint}-6 {
    width: 50%; }

  .w#{$breakpoint}-7 {
    width: 58.333333%; }

  .w#{$breakpoint}-8 {
    width: 66.666667%; }

  .w#{$breakpoint}-9 {
    width: 75%; }

  .w#{$breakpoint}-10 {
    width: 83.333333%; }

  .w#{$breakpoint}-11 {
    width: 91.666667%; }

  .w#{$breakpoint}-12 {
    width: 100%; } }

@mixin width-percentages {
  @include width-percentages-item('');

  @each $breakpoint, $media in $breakpointsForLoop {
    @media #{$media} {
      @include width-percentages-item('-#{$breakpoint}'); } } }

@mixin btn-display-utilities {
  @each $breakpoint, $media in $breakpointsForLoop {
    @media #{$media} {
      .btn-#{$breakpoint}-block {
        display: block !important;
        width: 100% !important; }

      .btn-#{$breakpoint}-inline {
        display: inline !important;
        width: auto !important; }

      .btn-#{$breakpoint}-flex {
        display: flex !important;
        width: auto !important; } } } }

@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

@mixin absolutely-centered {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; }

@mixin flex-centered {
  align-items: center;
  justify-items: center; }
