.cart-icon {
    width: 55px;
    height: 55px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
    .shopping-icon {
      width: 55px;
      height: 55px;
    }
  
    .item-count {
      position: absolute;
      font-size: 14px;
      font-weight: bold;
      bottom: 12px;
    }
  }