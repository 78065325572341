.search-box {
  padding: ($baseUnit*2);
  display: inline-block;
  width: 100%;

  &.overlay-top {
    margin-top: (-$baseUnit*3);
    position: relative; }

  &.search-primary {
    background: $colourRed;
    position: relative;
    overflow: hidden; }

  .search-title {
    font: normal normal $headingFontSizeLG $brandFamily;
    color: $colourWhite;
    margin-right: $baseUnit*2;
    margin-top: $baseUnit;
    float: left; }

  .search-box-form {
    clear: left;
    float: left;
    width: 100%;
    position: relative;

    .search-input {
      @include border-radius(0);
      border: none;
      height: 40px;
      width: 100%;
      margin-bottom: 0;

      &.input-with-btn {
        padding-right: ($baseUnit*4); } }

    .search-submit {
      top: 0;
      position: absolute;
      right: 0;
      background: transparent;
      border: none;
      bottom: 0;
      padding: 0;
      width: 40px;

      .icon {
        @include square(24px);
        fill: $colourGreyDark; } } }

  .search-related.list {
    float: left;
    list-style: none;
    margin-bottom: 0;

    li {
      float: left;
      padding: 0 $baseUnit;

      &:first-child {
        padding-left: 0;
        border: none; }

      &:last-child {
        padding: 0 0 0 $baseUnit; }

      a {
        color: $colourWhite;
        text-decoration: underline; } } }

  .search-title-options {
    margin-top: $baseUnit*0.6;
    position: relative;
    float: left;
    background: $colourBrickRed;
    width: 350px;
    height: 40px;
    padding: 0;

    select {
      background: none;
      border: none;
      position: absolute;
      top: 0;
      left: 0;
      height: ($baseUnit*4);
      width: 100%;
      color: $colourWhite;
      font: normal normal $headingFontSize $brandFamily;
      margin: 0;
      appearance: none;
      padding: #{($baseUnit*0.4)} #{($baseUnit*0.6)};

      option {
        color: black !important; } }

    select::-ms-expand {
      display: none; }

    .icon {
      position: absolute;
      right: 10px;
      top: 0;
      @include square(map-get($svgWidths, 'sm'));
      margin: ($baseUnit*1.2) 0 0 $baseUnit;
      border: none;
      background: none;
      fill: $colourWhite;

      &:focus {
        outline: none; }

      .icon {
        margin: 0;
        padding: 0;
        line-height: ($baseUnit*4); } } }

  .call-to-action {
    z-index: 2;
    position: relative;
    padding-left: ($baseUnit*5);
    text-align: center;
    color: $colourWhite;

    .call-to-action-category {
      font: normal 16px/22px $brandFamily;
      color: $colourWhite;
      margin: 0 0 ($baseUnit*0.5) 0; }

    h4 {
      a {
        color: $colourWhite; } }

    p {
      .btn {
        display: inline-block; } } }

  .call-to-action-link {
    font-family: $brandFamily;
    display: inline-block;
    color: $colourWhite;

    &:hover {
      text-decoration: underline;
      color: $colourWhite; }

    .icon {
      @include square(map-get($svgWidths, 'xs'));
      margin-left: ($baseUnit*0.5);
      fill: $colourWhite; } }

  .green-bg {
    background: linear-gradient(90deg, rgba(0, 166, 84, 1) 0%, rgba(73, 159, 127, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00A654', endColorstr='#499F7F', GradientType=1);
    transform: rotate(10deg);
    position: absolute;
    top: -40px;
    right: -40px;
    width: 380px;
    bottom: -40px; } }

@import 'search-box-xs-down';
@import 'search-box-xs-only';
@import 'search-box-sm-only';
@import 'search-box-md-down';
@import 'search-box-md-only';
@import 'search-box-lg-only';
@import 'search-box-xl-only';
