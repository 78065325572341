@media #{$breakpoint-md-up} {
  .nav-section {
    max-width: map-get($container-max-widths, 'md');
    margin: 0 auto $baseUnit*4;

    .navbar {
      margin: 0;

      .nav {
        .nav-item {
          &:first-child {
            padding-left: 0; }

          &.last-item {
            padding-right: 0; } } } }

    &-title {
      padding-left: 0;
      padding-right: 0; }

    &-subtitle {
      font-family: $boldFontFamily;
      margin-top: $baseUnit*0.5; }

    &.sticky-top {
      .navbar {
        margin: 0; } }

    &:after {
      right: 10px; } } }
