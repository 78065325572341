@media #{$breakpoint-xl-up} {
  .nav-global {
    &-logo {
      margin: ($baseUnit * 2) ($baseUnit * 2) ($baseUnit * 2) 0; }

    &-links-dropdown {
      &-highlight {
        &-bold {
          .text-md {
            font-size: $globalMenuHighlightXLMDFontSize !important;
            line-height: $globalMenuHighlightXLMDLineHeight !important; }

          .text-sm {
            font-size: $globalMenuHighlightXLSMFontSize !important;
            line-height: $globalMenuHighlightXLSMLineHeight !important; } } } }

    &--minimal {
      .nav-global {
        &-context {
          margin-right: 0; }

        &-logo {
          margin-left: 0; } } } } }
