@media #{$breakpoint-lg-up} {
  .nav-section {
    max-width: 100%;
    display: block;
    width: 100%;

    .navbar {
      overflow: hidden;

      .nav {
        .nav-item {
          .nav-link {
            &:focus {
              background-color: $colourSweetCorn; } }
          &.active {
            .nav-link:focus {
              color: darken($colourRed, 5%); } }

          &.dropdown-more {
            .dropdown-menu-more {
              @include border-radius(0);
              padding-top: 0;
              padding-bottom: 0;
              margin-top: 0;
              background-color: $colourWhite;
              box-shadow: 0px #{($baseUnit*0.5)} $baseUnit 0px rgba($colourBlack,0.1);
              border: none;

              .nav-item {
                padding: 0;
                width: 100%;

                .nav-link {
                  padding: $baseUnit*0.75 $baseUnit*1.5;
                  font-size: map-get($fontSizes, 'normal');
                  white-space: nowrap;
                  border: none;
                  border-left: $baseUnit*0.4 solid $colourWhite; } } }

            &:hover {
              .dropdown-toggle {
                border-color: $colourTransparent; } }

            .nav-item {
              .nav-link {
                color: $colourGreyDarker;
                text-shadow: none;
                padding: ($baseUnit*1.5);

                &:focus {
                  border-left-color: $colourSweetCorn; }

                &:hover {
                  border-color: $colourTintPrime; } }

              &.active {
                .nav-link {
                  border-left: $baseUnit*0.4 solid $colourRed;
                  color: $colourRed;

                  &:hover {
                    border-left: $baseUnit*0.4 solid $colourRed;
                    color: $colourRed; } } } }

            &:hover {
              color: $colourGreyDarkest;

              .dropdown-menu {
                display: block; } } } } } }

    &.loaded {
      .navbar {
        overflow: visible; } }

    &:after {
      content: unset; } } }
