/* Landscape tablet to desktop */
@media #{$breakpoint-lg-up} {
  .pagination {
    .page-item {
      .page-link {
        &:focus {
          outline: #{$baseUnit * 0.3} solid $colourSweetCorn; } } }

    &:first-child,
    &:last-child {
      .page-link {
        &:focus {
          outline: none;
          box-shadow: 0 0 0 ($baseUnit * 0.3) $colourSweetCorn; } } } } }
