/* Animation functions */
$standardAnimationCubic                   : cubic-bezier(.55,.055,.675,.19);
$standardAnimationDuration                : 350ms;
$shortAnimationDuration                   : 250ms;
$veryShortAnimationDuration               : 150ms;

/* Moible nav animations */
$mobileMenuSlideDuration:                 $standardAnimationDuration;
$mobileManuSlideTiming:                   $standardAnimationCubic;

/* Mobile nav burger icon */
$mobileMenuBurgerDuration:                $shortAnimationDuration;
$mobileMenuBurgerDelay:                   $veryShortAnimationDuration;
$mobileMenuBurgerTiming:                  $standardAnimationCubic;
$mobileMenuBurgerMiddleDuration:          175ms;
$mobileMenuBurgerMiddleDelay:             $mobileMenuBurgerMiddleDuration;

/* Search global */
$searchGlobalClearDuration:               100ms;
$searchGlobalClearTiming:                 $standardAnimationCubic;
