@media #{$breakpoint-xs-down} {
  .l-home {
    .content {
      padding-top: 0;
      padding-bottom: 0; }

    .home-calltoaction {
      padding-left: $baseUnit*2;
      padding-right: $baseUnit*2;
      border: solid $baseUnit*0.1 $colourGreyLight;
      margin-top: $baseUnit*2;
      margin-bottom: $baseUnit*-1; }

    .quote-homepage {
      position: relative;
      min-height: 0;
      padding: $baseUnit*2 0 $baseUnit*2 0;
      margin-left: -$baseUnit;
      margin-right: -$baseUnit;

      .quote {
        &.with-image {
          padding-left: 0;
          padding-right: 0; } }

      .quote-image {
        display: none; }

      .closing-quote {
        display: none; }

      blockquote {
        &.quote-body {
          padding-right: $baseUnit;

          p {
            font-family: $boldFontFamily;
            font-size: map-get($headingFontSizes, 'lg');
            line-height: map-get($headingLineHeights, 'large'); } } }

      figcaption {
        &.quote-author {
          font-family: $boldFontFamily;
          font-size: map-get($headingFontSizes, 'normal'); } }

      .btn {
        margin-bottom: $baseUnit*2; } } } }
