.footer-local {
  background-color: $colourGreySofter;
  padding: #{($baseUnit * 4)} #{($baseUnit * 2)};
  margin: 0;
  order: 6;
  grid-area: footer-local;

  .icon {
    color: $colourTintPrime; }

  .lead {
    font: normal $headingFontSizeSM $headingsFontFamily;
    clear: both; }

  .list.list-icons {
    li {
      margin-right: $baseUnit;
      a {
        .icon {
          margin-right: 0; } } } }

  &-title {
    font-family: $boldFontFamily;
    font-size: map-get($headingFontSizes, 'normal');
    line-height: map-get($headingLineHeights, 'normal');
    margin-bottom: ($baseUnit * 2);

    small {
      display: block;
      font: normal $headingFontSizeLG $headingsFontFamily;
      line-height: 2.1875rem;
      color: $colourText; }

    a {
      display: inline; } }

  &-logo {
    float: left;
    margin: 0 #{($baseUnit * 2)} #{($baseUnit * 2)} 0;

    img {
      @include square(60px); } }

  &-subtitle {
    font-family: $boldFontFamily;
    line-height: map-get($lineHeights, "normal");
    font-size: map-get($fontSizes, "normal");
    font-weight: 400;
    margin-bottom: $baseUnit; }

  & + .footer-global {
    margin-top: 0; }

  &-divider {
    margin: 0 0 ($baseUnit * 2) 0; } }

@import "footer-local-md-up";
@import "footer-local-lg-only";
