$horizontalPipeGutter: $baseUnit * 2;

@media #{$breakpoint-md-up} {
  .list {
    &.list-links {
      &.list-columns {
        &-2 {
          columns: 2; }

        &-3 {
          columns: 3; } } }

    &-horizontal {
      &.pipe-separated {
        display: flex;
        flex-wrap: wrap;

        li {
          display: flex;

          &:last-child {
            margin-bottom: $baseUnit * 0.5; }

          &:not(:last-child) {
            display: flex;
            align-items: center;
            padding-right: $horizontalPipeGutter;

            &::after {
              content: '';
              width: 1px;
              background: $colourTintPrime;
              height: 12px;
              margin-left: $horizontalPipeGutter; } } } } } } }
