/* Font family names */
$FranklinGothicBookIt: 'franklin_gothic_fs_bookitalic';
$FranklinGothicBook: 'franklin_gothic_fs_bookRg';
$FranklinGothicURW: 'franklin_gothic_URW_lig';
$FranklinGothicMed: 'franklin_gothic_fs_medregular';
$FranklinGothicMedIt: 'franklin_gothic_fs_meditalic';
$FranklinGothicDemi: 'franklin_gothic_fs_demi';
$fontFamilyBody: Helvetica, Arial, sans-serif;
$fontFamilyHeading: Helvetica, Arial, sans-serif;
$sansFontFamily: Arial, 'Helvetica Neue', sans-serif;
$serifFontFamily: Georgia, 'Times New Roman', Times, serif;
$headingsFontFamily: $FranklinGothicBook, $sansFontFamily;

/* Font weights */
$fontWeightBody: 400;
$fontWeightHeading: 400;

/* Font family with fallbacks */
$baseFontFamily: $sansFontFamily;
$italicFamily: $FranklinGothicBookIt, $sansFontFamily;
$brandFamily: $FranklinGothicBook, $sansFontFamily;
$boldFontFamily: $FranklinGothicMed, $sansFontFamily;
$altFontFamily: $serifFontFamily;
$demiFontFamily: $FranklinGothicDemi, $sansFontFamily;

/* Font sizes */
$fontSizes: ('xl': 1.5rem, 'lg': 1.25rem, 'normal': 1.0625rem, 'sm': 0.9375rem, 'xs': 0.75rem, 'xxs': 0.6875rem);
$lineHeights: ('xxl': 2rem, 'xl': 1.825rem, 'lg': 1.75rem, 'normal': 1.5rem, 'sm': 1.3125rem, 'xs': 1rem, 'xxs': 1rem);
$headingFontSizes: ('xxxl': 2.75rem, 'xxl': 2.25rem, 'xl': 2.125rem, 'lg': 1.5rem, 'normal': 1.375rem, 'sm': 1.125rem, 'xs': 1rem, 'xxs': 0.75rem);
$headingLineHeights: ('xxxl': 3.125rem, 'xxl': 2.7rem, 'xl': 2.5rem, 'lg': 1.875rem, 'normal': 1.8rem, 'sm': 1.5rem, 'xs': 1.375rem, 'xxs': 1rem);

/* Heading font size and line-height */
$headingFontSizeXXXL: map-get($headingFontSizes, 'xxxl') #{'/'} map-get($headingLineHeights, 'xxxl');
$headingFontSizeXXL: map-get($headingFontSizes, 'xxl') #{'/'} map-get($headingLineHeights, 'xxl');
$headingFontSizeXL: map-get($headingFontSizes, 'xl') #{'/'} map-get($headingLineHeights, 'xl');
$headingFontSizeLG: map-get($headingFontSizes, 'lg') #{'/'} map-get($headingLineHeights, 'lg');
$headingFontSize: map-get($headingFontSizes, 'normal') #{'/'} map-get($headingLineHeights, 'normal');
$headingFontSizeSM: map-get($headingFontSizes, 'sm') #{'/'} map-get($headingLineHeights, 'sm');
$headingFontSizeXS: map-get($headingFontSizes, 'xs') #{'/'} map-get($headingLineHeights, 'xs');

$tagFontSize: 0.875rem;

/* Global nav highlights */
$globalMenuHighlightMDXXLFontSize: 140px;
$globalMenuHighlightMDXXLLineHeight: 140px;
$globalMenuHighlightMDXXLLetterSpacing: -4.08px;

$globalMenuHighlightMDXLFontSize: 100px;
$globalMenuHighlightMDXLLineHeight: 100px;
$globalMenuHighlightMDXLLetterSpacing: -4.08px;

$globalMenuHighlightMDLGFontSize: 90px;
$globalMenuHighlightMDLGLineHeight: 90px;
$globalMenuHighlightMDLGLetterSpacing: -4.08px;

$globalMenuHighlightMDMDFontSize: 68px;
$globalMenuHighlightMDMDLineHeight: 68px;
$globalMenuHighlightMDMDLetterSpacing: -2.27px;

$globalMenuHighlightMDSMFontSize: 50px;
$globalMenuHighlightMDSMLineHeight: 50px;
$globalMenuHighlightMDSMLetterSpacing: -1px;

$globalMenuHighlightMDXSFontSize: 40px;
$globalMenuHighlightMDXSLineHeight: 40px;
$globalMenuHighlightMDXSLetterSpacing: -1px;

// XL
$globalMenuHighlightXLMDFontSize: 75px;
$globalMenuHighlightXLMDLineHeight: 75px;

$globalMenuHighlightXLSMFontSize: 55px;
$globalMenuHighlightXLSMLineHeight: 55px;

$globalMenuHighlightTextFontSize: 1.5rem;
$globalMenuHighlightTextLineHeight: 1.8125rem;

/* Mobile navigation */
$mobileNavBackFontSize: 1.125rem;
$mobileNavBackLineHeight: 1.75rem;
$mobileNavFontSize: 1.5rem;
$mobileNavLineHeight: 1.875rem;
$smallFontSize: 0.875rem;

$quoteFontSizeSmall: 1.1875rem;
$quoteLineHeightSmall: 1.5625rem;
$quoteLineHeightLarge: 2.25rem;
$quoteRichFontSize: 1.625rem;

$font15: ('size': 0.9375rem, 'line-height': 1.3125rem);
$font17: ('size': 1.0625rem, 'line-height': 1.5rem);
$font18: ('size': 1.125rem, 'line-height': 1.5rem);
$font20: ('size': 1.25rem, 'line-height': 1.8125rem);
$font24: ('size': 1.5rem, 'line-height': 1.875rem);
$font28: ('size': 1.75rem, 'line-height': 2.25rem);
$font36: ('size': 2.25rem, 'line-height': 2.375rem);
$font40: ('size': 2.5rem, 'line-height': 3rem);
$font48: ('size': 3rem, 'line-height': 3.5rem);
$font50: ('size': 3.125rem, 'line-height': 3.625rem);
$font60: ('size': 3.75rem, 'line-height': 4.5rem);
