@media #{$breakpoint-md-up} {
  .modal {
    &-body,
    &-summary {
      padding-right: $baseUnit * 2; }

    &-header {
      padding-right: $baseUnit * 0.5; }

    &-facet {
      .modal-body {
        form {
          .form-buttons {
            margin-right: -$baseUnit * 2; } } } }

    &-image {
      .modal-dialog {
        max-width: map-get($container-max-widths, 'md'); } } } }
