.f-fg {
  &-book {
    font-family: $brandFamily !important; }

  &-book-light {
    font-family: $italicFamily !important; }

  &-med {
    font-family: $boldFontFamily !important; }

  &-demi {
    font-family: $demiFontFamily !important; } }

.text {
  &-xs {
    font-size: map-get($fontSizes, 'xs') !important;
    line-height: map-get($lineHeights, 'xs') !important; }

  &-sm {
    font-size: map-get($fontSizes, 'sm') !important;
    line-height: map-get($lineHeights, 'sm') !important; }

  &-normal {
    font-size: map-get($fontSizes, 'normal') !important;
    line-height: map-get($lineHeights, 'normal') !important; }

  &-md {
    font-size: map-get($fontSizes, 'lg') !important;
    line-height: map-get($lineHeights, 'lg') !important; }

  &-lg {
    font-size: map-get($fontSizes, 'xl') !important;
    line-height: map-get($lineHeights, 'xl') !important; }

  &-underline {
    text-decoration: underline !important; }

  &-break-all {
    word-break: break-all !important;
    overflow-wrap: break-word !important; } }

.font {
  &-bold {
    font-family: $boldFontFamily !important; }

  &-demi {
    font-family: $demiFontFamily !important; } }

.text,
.colour {
  &-grey {
    color: $colourGrey !important; }

  &-red {
    color: $colourRed !important; } }
