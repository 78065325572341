@media #{$breakpoint-lg-up} {
  .modal {
    padding: 0;

    &-content {
      min-height: auto; }

    &-facet {
      .modal-body {
        form {
          .form {
            &-buttons {
              margin: 0 (-$baseUnit * 2.5) 0 (-$baseUnit * 3);
              padding-left: $baseUnit * 3;
              padding-right: $baseUnit * 3; } } } } }

    .modal {
      &-dialog {
        margin: ($baseUnit * 5) auto;
        max-width: 972px;

        button:focus {
          background-color: $colourSweetCorn;
          outline: none; } }

      &-body,
      &-summary {
        padding-left: $baseUnit * 3;
        padding-right: $baseUnit * 2.5; }

      &-header {
        padding-left: $baseUnit * 3;
        padding-right: $baseUnit; } }

    &-image {
      .modal {
        &-dialog {
          max-width: map-get($container-max-widths, 'lg');
          margin: 0 auto; }

        &-header {
          .close-modal {
            &:focus {
              color: $colourGrey;

              .icon {
                fill: $colourGrey; } } } }

        &-body {
          padding: 0; } } } } }
