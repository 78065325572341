@media #{$breakpoint-lg-up} {
  .nav-global {
    order: 3;

    .navbar {
      height: auto;
      padding: 0;
      z-index: 1002; }

    &-logo {
      margin: $baseUnit * 2;
      flex-basis: $logoWidth;
      justify-self: start;

      svg {
        @include square($logoWidth);

        margin: 0; } }

    &-links {
      display: flex;
      justify-self: start;
      margin: 0;

      > .nav-item {
        > .nav-link {
          font-size: map-get($fontSizes, 'lg');
          margin: 0 ($baseUnit * 1.5); }

        &:first-child {
          > .nav-link {
            margin-left: $baseUnit; } } }

      .nav-link {
        text-decoration: none;
        color: $colourGreyDarker; }

      > .nav-item {
        height: $baseUnit * 9;
        margin-top: $baseUnit * 2;

        > .nav-link {
          font-size: map-get($fontSizes, 'lg');
          padding: ($baseUnit * 2) 0;
          margin: 0 $baseUnit;
          position: relative;

          &.active {
            border-bottom: ($baseUnit * 0.4) solid $colourRed; } }

        &:first-child {
          > .nav-link {
            margin-left: 0; } }

        &.active-hover {
          > .nav-link {
            color: $colourRed;

            &:focus {
              color: $colourGreyDarker; } }

          > .nav-link.with-dropdown {
            @include global-nav-active-arrow;

            + .nav-global-links-dropdown {
              display: block; } } } } }

    &-links-dropdown {
      display: none;
      position: absolute;
      left: 0;
      top: calc(100% + 1px);
      right: 0;
      z-index: 1002;
      min-height: 340px;
      padding: 0;
      overflow: hidden;
      background-color: $colourWhite;
      padding: $baseUnit * 2.5;
      border-bottom: 1px solid $colourGreySoft;

      &.active-hover {
        display: block;

        + .nav-link {
          @include global-nav-active-arrow;

          color: $colourRed; } }

      &-highlight {
        display: flex;
        flex-direction: column;
        position: relative;
        background: $colourTransparent;
        align-content: center;
        justify-content: center;
        min-height: 290px;
        height: 100%;
        word-break: break-word;
        padding: 0 0 0 ($baseUnit * 1.5);
        text-align: center;
        color: $colourWhite;

        .text-lg,
        .text-sm {
          margin-bottom: 0; }

        .font-bold,
        &-bold,
        strong {
          font-family: $demiFontFamily; }

        &:before {
          content: '';
          position: absolute;
          width: 200%;
          height: 200%;
          top: -90px;
          left: 30px;
          z-index: -1;
          background: linear-gradient(-180deg, rgba($colourBlueZodiac, 0.8) 0%, rgba($colourBlueZodiac, 1) 100%);
          transform-origin: top left;
          transform: rotate(10deg); }

        &-bold {
          color: $colourRegentGrey;
          margin: 0 auto;

          span {
            display: inline-block; }

          .text-xxl {
            font-size: $globalMenuHighlightMDXXLFontSize !important;
            line-height: $globalMenuHighlightMDXXLLineHeight !important;
            letter-spacing: $globalMenuHighlightMDXXLLetterSpacing; }

          .text-xl {
            font-size: $globalMenuHighlightMDXLFontSize !important;
            line-height: $globalMenuHighlightMDXLLineHeight !important;
            letter-spacing: $globalMenuHighlightMDXLLetterSpacing; }

          .text-lg {
            font-size: $globalMenuHighlightMDLGFontSize !important;
            line-height: $globalMenuHighlightMDLGLineHeight !important;
            letter-spacing: $globalMenuHighlightMDLGLetterSpacing; }

          .text-md {
            font-size: $globalMenuHighlightMDMDFontSize !important;
            line-height: $globalMenuHighlightMDMDLineHeight !important;
            letter-spacing: $globalMenuHighlightMDMDLetterSpacing; }

          .text-sm {
            font-size: $globalMenuHighlightMDSMFontSize !important;
            line-height: $globalMenuHighlightMDSMLineHeight !important;
            letter-spacing: $globalMenuHighlightMDSMLetterSpacing; }

          .text-xs {
            font-size: $globalMenuHighlightMDXSFontSize !important;
            line-height: $globalMenuHighlightMDXSLineHeight !important;
            letter-spacing: $globalMenuHighlightMDXSLetterSpacing; } } }

      .nav {
        margin: 0;

        .nav-item {
          display: block;
          width: 100%;
          margin-bottom: $baseUnit;

          &:last-child {
            margin-bottom: 0; }

          .nav-link {
            padding: 0;
            margin: 0 0 #{$baseUnit * 0.5} 0;
            display: inline-block;

            &:hover {
              text-decoration: underline; } }

          .nav-title {
            font-family: $boldFontFamily;
            font-size: map-get($fontSizes, 'lg');
            line-height: map-get($lineHeights, 'XL');
            margin-bottom: 5px;

            &-section {
              display: inline-block;
              margin-bottom: #{$baseUnit * 1.5}; } } } } }

    &-menu {
      width: auto; }

    &-context {
      font-size: map-get($fontSizes, 'sm');
      text-decoration: none;
      color: $colourGreyDarker;

      .icon {
        margin-left: $baseUnit; } }

    .nav-global-cy {
      .nav-collapse {
        .nav a {
          padding: ($baseUnit * 1.5) $baseUnit; } }

      .navbar-search {
        .search-input {
          width: 150px; } } } } }
