.search-bar {
  background: $colourGreyLightest;
  height: 130px;
  margin-top: #{-$baseUnit * 4.1};

  .search-body {
    .search-form {
      margin-top: #{$baseUnit * 4};
      margin-bottom: #{$baseUnit * 2.4};
      position: relative;

      .search-query {
        height: 49px;
        width: 100%;
        font-size: 1.625rem;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 50px;
        padding: #{$baseUnit * 0.4} 0;
        border: solid #{$baseUnit * 0.1} $colourGreyLight;
        text-indent: 10px; }

      .btn {
        border: none;
        margin: 0;
        border-radius: 0;
        height: 49px;
        padding: 0;
        position: absolute;
        top: 0;
        right: -1px;
        bottom: 0;
        width: 50px;

        .icon {
          @include square(26px);

          margin: 0;
          padding: 0;
          fill: $colourWhite; } }

      button {
        &.clear {
          position: absolute;
          right: 60px;
          margin-top: #{$baseUnit * 1.2};
          margin-right: #{$baseUnit * 0.6};
          padding: 0;
          background-color: transparent;
          border: none;

          .icon {
            @include square(map-get($svgWidths, 'sm'));

            fill: $colourGrey;
            color: $colourGrey; } } } } } }

.search-results {
  h4 {
    margin-bottom: $baseUnit * 0.5;

    a {
      color: $colourLink; } }

  h2 {
    a {
      color: $colourSteelBlue; } }

  .teaser {
    border-top: solid #{$baseUnit * 0.1} #{$colourBtnBorder};
    padding: $baseUnit * 2 #{$baseUnit * 2} 0 #{$baseUnit * 2};

    a {
      color: $colourLink; }

    .teaser-icon {
      float: left; }

    .teaser-category {
      text-transform: none; }

    .teaser-body {
      h1 {
        &.teaser-title {
          font-size: map-get($headingFontSizes, 'lg');
          padding-bottom: 0;
          display: block; } }

      h2 {
        &.teaser-subtitle {
          padding-top: $baseUnit;
          font-size: map-get($headingFontSizes, 'normal');
          line-height: map-get($headingLineHeights, 'normal'); } }

      p {
        padding-top: $baseUnit;

        &.teaser-link {
          padding-top: 0;
          word-wrap: break-word; } } }

    &.teaser-curation {
      border: none;
      background: $colourAlertWarningBackground;
      padding: $baseUnit * 2; } } }

.search-filters {
  .btn {
    font-size: map-get($fontSizes, 'sm');
    background: $colourGreyLighter;
    border: solid #{$baseUnit * 0.1} $colourPrimeGrey;
    padding: #{$baseUnit * 0.8};

    &:hover {
      background: $colourGreyLightest; }

    &.btn-success {
      font-size: map-get($fontSizes, 'sm');
      background: $colourJade;
      padding: #{$baseUnit * 0.8};
      border: none;

      &:hover {
        background: $colourJade;
        border: none; }

      .icon {
        @include square(map-get($svgWidths, 'xs'));

        fill: $colourWhite;
        margin-left: $baseUnit * 0.5; } }

    .icon {
      @include square($iconSize18);

      fill: $colourBlack;
      margin-right: #{$baseUnit * 0.5}; } } }

@import "search-sm-down";
@import "search-sm-only";
@import "search-lg-only";
@import "search-xl-up";
