@media #{$breakpoint-lg-up} {
  .footer-global {
    .list {
      &-links {
        li {
          a {
            &:focus {
              background-color: $colourSweetCorn;
              color: $colourBlack;
              outline: none;
              box-shadow: 0 0 0 #{$baseUnit * 0.5} #{$colourSweetCorn}; } } } } }

    .list.footer-global {
      &-icons {
        li {
          a {
            &:focus {
              background-color: $colourSweetCorn;
              padding: #{$baseUnit / 2} 0;

              .icon {
                fill: $colourBlack;
                margin-right: 0;

                &-background {
                  fill: $colourBlack; } } } }

          &:last-child {
            a {
              &:focus {
                margin-right: 0; } } } } }

      &-legal {
        a {
          &:focus {
            background-color: transparent;
            color: $colourGreyLighter;
            box-shadow: 0 0 0 #{$baseUnit * 0.3} $colourSweetCorn; } } }

      &-logos.list {
        li {
          margin-right: $baseUnit*4;

          a:focus {
            text-decoration: none;
            padding: 0;
            background-color: transparent;
            box-shadow: none; } } } } } }
