$activeSectionHover: darken($colourRed, 10%);

@media #{$breakpoint-lg-up} {
  .nav-local {
    @include local-nav-level-padding;

    display: block;

    > .nav-item:first-child {
      .nav-link {
        font-family: $boldFontFamily;
        background-color: $colourGreyLighter;
        font-size: map-get($fontSizes, 'lg');
        border-bottom: ($baseUnit * 0.2) solid $colourWhite; } }

    .nav-item {
      list-style: none;
      border-bottom: ($baseUnit * 0.1) solid $colourGreyLighter;

      .nav-link {
        padding: ($baseUnit * 1.5) ($baseUnit * 1.85);
        color: $colourGreyDarker;
        text-decoration: none;

        &:hover {
          color: $colourGreyDarkest; } } }

    .nav-local-section {
      border-bottom: ($baseUnit * 0.4) solid $colourGreyLighter;

      > .nav-link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $boldFontFamily;

        .nav-local-section-text {
          width: calc(100% - #{$baseUnit * 2.1}); } }

      &-open {
        > .nav-link {
          background-color: $colourGreyLighter;

          .icon {
            @include square(map-get($svgWidths, 'xs'));

            margin-right: 0;
            fill: $colourLocalNaviation;
            margin: 0 0 0 ($baseUnit * 0.5);
            flex-shrink: 0; }

          &:hover {
            color: $colourGreyDarker; }

          &.active {
            background-color: $colourRed;
            color: $colourWhite;

            .icon {
              fill: $colourWhite; }

            &:hover {
              color: $colourWhite;
              background-color: $activeSectionHover; } } }

        .nav-local-section-open {
          .nav-local-section-open {
            .nav-link:not(.active) {
              border-top: ($baseUnit * 0.1) solid $colourWhite; } } }

        .nav-item {
          &:last-child {
            border-bottom: none; } } } }

    .nav-link.active {
      color: $colourRed;
      font-family: $boldFontFamily;

      &.level-2 {
        color: darken($colourRed, 5%); }

      &:hover {
        color: $colourRed; } }

    .nav-local-section-open > .nav-link.active.level-2 {
      color: $colourWhite; } } }
