.sticky {
  &-bottom {
    @supports (position: sticky) {
      position: sticky;
      bottom: 0;
      z-index: $zindex-sticky; } }

  &-top {
    &-before {
      min-height: 1px;
      min-width: 1px;
      position: absolute; } } }

.stuck {
  &-top {
    position: fixed !important;
    top: 0 !important;

    @supports (position: sticky) {
      position: sticky !important; } } }
