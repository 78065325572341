@media #{$breakpoint-md-up} {
  .footer-fixed {
    padding: #{$baseUnit * 3.5} #{$baseUnit * 2};

    .footer-fixed {
      &-contact,
      &-title {
        font-size: 1.25rem;
        line-height: 1.5rem; }

      &-contact {
        margin-top: 0;

        .icon {
          @include square(map-get($svgWidths, 'md') + 2); }

        a:focus {
          color: $colourBlack; } } } } }
