.footer-global {
  @include bg-colour($colourBlack);
  @extend %antialiasing;
  color: $colourGreyLight;
  margin: #{$baseUnit * 2} 0 0 0;
  padding: #{$baseUnit * 4} #{$baseUnit * 2};
  order: 7;
  grid-area: footer-global;

  .list {
    margin: 0; }

  &-icons {
    &.list.list-icons {
      @extend .float-md-right;
      margin-top: $baseUnit * 2;

      li {
        margin-right: $baseUnit*2;

        &:last-child {
          margin-right: 0; }

        .icon {
          fill: $colourGreyLight;
          margin-right: 0;

          &-background {
            fill: $colourGreyLight !important; }

          &-foreground {
            fill: $colourTransparent !important; } }

        a:focus {
          .icon-background {
            fill: $colourBlack !important; } } } } }

  a,
  a:hover {
    color: $colourGreyLighter; }

  .footer {
    &-section-title {
      font-size: #{$baseUnit * 2};
      margin: #{$baseUnit*2} 0 #{$baseUnit * 0.5} 0;
      line-height: #{$baseUnit * 2.8}; }

    &-links {
      li {
        margin-bottom: $baseUnit;
        a {
          font-style: normal; } } } }

  &-legal.list {
    li {
      &:last-child {
        margin-right: 0; } } }

  &-logos.list.list-links {
    margin: #{$baseUnit * 4} 0 0 0;

    li {
      margin: 0 $baseUnit*2 0 0; }

    .footer-global-logos-image {
      margin-bottom: $baseUnit * 2; } }

  hr {
    border-color: $colourGreyDarkest;
    height: 1px; }

  h2 {
    color: $colourWhite; } }

@import 'footer-global-md-up';
@import 'footer-global-lg-up';
