$logoWidth: 70px;
$logoWidthSM: 50px;
$contextWidth: 93px;
$mobileNavHeight: 70px;

.nav-global {
  font-weight: normal;
  text-shadow: none;
  font-family: $brandFamily;
  max-width: 1230px;
  margin: auto;
  z-index: 1039;
  width: 100%;
  position: relative;
  order: 1;
  grid-area: nav-global;

  .navbar {
    @extend .flex-row;

    @extend .justify-content-start;

    align-items: center;
    max-width: 1170px;
    margin: auto;
    padding: $baseUnit 0;
    margin-bottom: 0;
    display: flex;
    border-bottom: ($baseUnit * 0.1) solid $colourGreySoft;
    height: $mobileNavHeight;

    .navbar-inner {
      padding: 0; } }

  &-menu {
    width: calc((100% - #{$logoWidthSM}) / 2);

    .nav-global-btn {
      align-items: center;
      border: none;
      padding: #{$baseUnit * 1.3} #{$baseUnit * 1.5};
      color: $colourBlack;
      background-color: $colourWhite;
      text-decoration: none;

      .icon {
        .bar {
          &:nth-child(4),
          &:nth-child(2) {
            transform: rotate(0);
            transition: transform $mobileMenuBurgerDuration $mobileMenuBurgerTiming $mobileMenuBurgerDelay; }

          &:nth-child(3) {
            transform: scale(1);
            transition: transform $mobileMenuBurgerMiddleDuration cubic-bezier(0.55, 0.055, 0.675, 0.19) $mobileMenuBurgerMiddleDelay; } } }

      &.menu-open {
        .icon {
          .bar {
            &:nth-child(3) {
              transform: scale(0); }

            &:nth-child(4) {
              transform: rotate(-45deg) translate(-11px, -2px); }

            &:nth-child(2) {
              transform: rotate(45deg) translate(2px, -5px); } } } } } }

  &-logo {
    a {
      display: block; }

    svg {
      @include square($logoWidthSM);

      margin: 0; } }

  &-links {
    display: none; }

  &-context {
    text-align: right;

    a {
      font-size: map-get($fontSizes, 'sm');
      text-decoration: none;
      color: $colourGreyDarker;

      .icon {
        margin-left: $baseUnit; } } }

  &-search {
    a {
      padding: $baseUnit * 1.5; }

    .icon {
      margin: 0; } }

  &--minimal {
    margin-top: $baseUnit * 4;
    margin-bottom: $baseUnit * 2;

    .navbar {
      align-items: start;
      border-bottom: 0;
      min-height: $logoWidth;
      padding: 0; }

    .nav-global {
      &-context {
        margin-right: $baseUnit * 2; }

      &-logo {
        margin: 0 ($baseUnit * 2) 0 ($baseUnit * 2);

        svg {
          @include square($logoWidth); } } } } }

@import "nav-global-lg-up";
@import "nav-global-xl-up";
