.alert,
.sq-form-errors-message {
  &:not(.alert-error, .alert-info, .alert-success) {
    @extend .alert-warning; }

  border-left: solid 4px darken($colourAlertWarningBackground, 10%);

  &-error {
    border-left: solid 4px darken($colourRed, 10%); }

  &-success {
    border-left: solid 4px darken($colourGreen, 10%); }

  &-info {
    border-left: solid 4px darken($colourInfo, 10%); }

  &-error,
  &-success,
  &-info {
    color: $colourWhite;

    .icon {
      fill: $colourWhite; }

    a {
      color: $colourWhite;
      text-decoration: underline;

      &:visited,
      &:active {
        color: $colourWhite;
        text-decoration: none; }

      &:hover {
        color: $colourWhite;
        text-decoration: underline; }

      &:focus {
        color: $colourBlack; } }

    .close {
      &:hover {
        color: $colourWhite; } } }

  strong {
    font-family: $boldFontFamily;
    font-weight: normal;

    a {
      text-decoration: underline;

      &:visited,
      &:active {
        text-decoration: none; }

      &:hover {
        text-decoration: underline; } } }

  .alert-title, h1, h2, h3, h4, h5, h6 {
    font-family: $brandFamily;
    font-size: map-get($headingFontSizes, "lg");
    line-height: map-get($headingLineHeights, "lg");
    margin-bottom: $baseUnit;
    display: block; }

  &-dismissible {
    .close {
      opacity: 1;

      &:focus {
        outline: $baseUnit*0.3 solid $colourSweetCorn; } } }

  &.with-icon {
    display: flex;
    padding: 0;
    border-left: none;

    &.alert,
    &.alert-warning {
      .alert-icon {
        background: darken($colourAlertWarningBackground, 10%); } }

    &.alert-error,
    &.alert-info,
    &.alert-success {
      .alert-title, h1, h2, h3, h4, h5, h6 {
        color: $colourWhite; } }

    &.alert-error {
      .alert-icon {
        background: darken($colourRed, 10%); } }

    &.alert-info {
      .alert-icon {
        background: darken($colourInfo, 10%); } }

    &.alert-success {
      .alert-icon {
        background: darken($colourSuccess, 10%); } }

    .alert-icon {
      width: $baseUnit * 6.5;
      padding: #{$baseUnit * 2.5} #{$baseUnit * 1.5};

      .icon {
        width: $baseUnit * 3.5;
        height: auto; } }

    .alert-body {
      padding: #{$baseUnit * 2}; } }

  &-site {
    grid-area: site-alert;
    width: 100%;
    padding: $baseUnit*2 $baseUnit;
    background: $colourOceanBreeze;
    font-size: map-get($fontSizes, "normal");
    text-align: center;
    color: $colourWhite;

    p {
      margin-bottom: 0; }

    a {
      color: $colourWhite;
      text-decoration: underline; } }

  &-fixed {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: inline-block;
    margin-bottom: 0;
    z-index: $zindex-sticky; }

  p, ul {
    &:last-child {
      margin-bottom: 0; } } }

@import 'alert-md-up';
@import 'alert-lg-up';
